import React, { useState } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userResetPassword } from '../apis/api-request';
import logo from '../assets/logo-dark.svg';
import lightLogo from '../assets/logo-light.svg';
import { CustomButton } from '../components/elements/CustomButton';
import InputField from '../components/elements/InputField';
import { useThemeContext } from '../contextapi/ThemeProvider';
import i18n from '../utils/i18n';

export const ForgotPasswordPage = () => {
  const { theme } = useThemeContext();
  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const resetPassword = await userResetPassword({ email });
    setLoading(false);
    if (resetPassword.status === 200) {
      toast.success(resetPassword.data.message);
      return true;
    }
    toast.error(resetPassword.data.message);
    return false;
  };

  return (
    <Row className="login g-0">
      <Col xs={8} sm={12} md={6} className="align-self-center mx-auto">
        <div className="login-container">
          <Image width="50%" src={theme === 'light' ? logo : lightLogo} />

          <h5 className="mb-12">{i18n.t('reset_password.title')}</h5>
          <p className="mb-24">{i18n.t('reset_password.body')}</p>

          <Form onSubmit={sendEmail}>
            <InputField
              className="mb-24"
              controlId="formEmail"
              type="email"
              required
              title={`${i18n.t('input.email.title')}`}
              placeholder={`${i18n.t('input.email.placeholder')}`}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Link
              to="/"
              className={`btn btn-link btn-link-${theme} ${theme === 'dark' ? 'white-color' : 'dark-color'}`}
            >
              {i18n.t('button.back_to_signin')}
            </Link>

            <CustomButton
              title={i18n.t('button.send_reset_link')}
              className="button-with-loader w-100"
              variant="primary"
              type="submit"
              disabled={loading}
              loading={loading}
            />
          </Form>
        </div>
      </Col>

      <Col className="login-background" md={6} />
    </Row>
  );
};
