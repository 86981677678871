/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Navigate, Outlet } from 'react-router-dom';
import { ReactComponent as DoneIcon } from '../assets/icons/done.svg';
import { ReactComponent as LoadingIcon } from '../assets/icons/loading.svg';
import BottomToast from '../components/elements/BottomToast';
import SideNavBar from '../components/nav/SideNavBar';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import i18n from '../utils/i18n';
import { useTracker } from '../utils/Tracker';

export const ProtectedLayout = () => {
  const { user, isCSVStatus } = useAuthContext();
  const { trackPage } = useTracker();
  const { theme } = useThemeContext();

  useEffect(() => {
    trackPage({
      url: window.location.pathname,
      theme,
    });
  }, []);

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="master-layout">
      <SideNavBar />
      <Container className="mx-master d-flex flex-column justify-context-center">
        <Outlet />
      </Container>
      {isCSVStatus && (
        <BottomToast
          icon={isCSVStatus === 'pending' ? LoadingIcon : DoneIcon}
          title={
            isCSVStatus === 'pending'
              ? `${i18n.t('bottom_toast.generating_csv_report')}`
              : `${i18n.t('bottom_toast.report_successfully_created')}`
          }
          variant={theme}
          buttonText={`${i18n.t('button.download')}`}
        />
      )}
    </div>
  );
};
