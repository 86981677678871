import { DocumentNode, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ObservationsTable } from './ObservationsTable';
import { useAuthContext } from '../../contextapi/AuthProvider';
import {
  DELETE_OBSERVATIONS,
  UPDATE_OBSERVATIONS,
} from '../../graphql/mutations';
import { ObservationsSetInput } from '../../graphql/mutations/groups';
import { UPDATE_OBSERVATION_BY_ID } from '../../graphql/mutations/observation';
import { ALERT_OBSERVATIONS_BY_INCIDENTS_ID } from '../../graphql/queries/alerts';
import {
  GET_BOOKMARKED_OBSERVATIONS,
  GET_OBSERVATIONS,
  WhereQueryProps,
} from '../../graphql/queries/observation';
import { ObservationProp } from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { isDefined, isNumber } from '../../utils/typeUtils';
import { Loader } from '../elements/Loader';

type Props = {
  alertId?: number;
  cameraId?: number;
  assignee?: string;

  hideCameraColumn?: boolean;
  variant?: 'default' | 'bookmark' | 'camera';
  // TODO: create proper type for timePeriod
  timePeriod?: {
    from: number;
    to: number;
  };
};

export function ObservationList({
  alertId,
  cameraId,
  assignee,
  hideCameraColumn = false,
  variant = 'default',
  timePeriod,
}: Props) {
  const { onTokenSave } = useAuthContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get('pageNo')
    ? Number(searchParams.get('pageNo'))
    : 1;
  const limitParam = searchParams.get('limit')
    ? Number(searchParams.get('limit'))
    : null;
  const filterParam: string = searchParams.get('filter')
    ? decodeURI(String(searchParams.get('filter')))
    : '{}';
  const filter: WhereQueryProps = JSON.parse(filterParam);

  const [limit, setLimit] = useState(limitParam || 10);
  const [queryVar, setQueryVar] = useState<WhereQueryProps>(filter);
  const [observations, setObservations] = useState<Array<ObservationProp>>([]);
  const [totalPages, setTotalPage] = useState(0);
  const [isOrder, setIsOrder] = useState(true);

  const isFilteredByAlert = !!isNumber(alertId);
  const offset = (pageNumber - 1) * limit;

  const query: DocumentNode = isFilteredByAlert
    ? ALERT_OBSERVATIONS_BY_INCIDENTS_ID
    : variant === 'bookmark'
      ? GET_BOOKMARKED_OBSERVATIONS
      : GET_OBSERVATIONS;

  const queryVariables = {
    ...(isFilteredByAlert && { id: alertId }),
    limit,
    offset,
    where: {
      is_false_positive: { _eq: false },
      ...(assignee && { responder: { _eq: assignee } }),
      ...(cameraId && { camera_id: { _eq: cameraId } }),
      ...(timePeriod && {
        system_timestamp: { _gte: timePeriod.from, _lte: timePeriod.to },
      }),
      ...queryVar,
    },
    orderBy: !isOrder ? 'asc' : 'desc',
  };

  const { loading, error, data, refetch } = useQuery(query, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, [timePeriod, refetch]);

  const [updateSensitiveObservation] = useMutation(UPDATE_OBSERVATION_BY_ID);
  const [updateObservation] = useMutation(UPDATE_OBSERVATIONS);
  const [deleteObservation, deleteActions] = useMutation(DELETE_OBSERVATIONS);

  const onDeleteObservation = (observationIds: Array<number>) => {
    deleteObservation({
      variables: {
        observationIds,
      },
    });
  };

  const onUpdateObservation = (
    observationIds: Array<number>,
    value: ObservationsSetInput,
  ) => {
    updateObservation({
      variables: {
        observationIds,
        data: value,
      },
    });
  };

  useEffect(() => {
    if (data) {
      if (isFilteredByAlert) {
        const observations = data.alert.alert_incident_observations.map(
          (item: any) => item.observation,
        );
        setTotalPage(
          Math.ceil(
            data.alert.alert_incident_observations_aggregate.aggregate.count /
              limit,
          ),
        );
        setObservations(observations);
      } else if (variant === 'bookmark') {
        const observationList = data.observation_user.map(
          (item: any) => item.observation,
        );
        setObservations(observationList);
        setTotalPage(
          Math.ceil(data.observation_user_aggregate.aggregate.count / limit),
        );
      } else {
        setTotalPage(
          Math.ceil(data.observations_aggregate.aggregate.count / limit),
        );
        setObservations(data.observations);
      }
    } else {
      setObservations([]);
    }
  }, [data, refetch, searchParams, variant, limit, isFilteredByAlert]);

  useEffect(() => {
    if (deleteActions.data) {
      toast.success(i18n.t('toast.success.deleted'), {
        autoClose: 500,
        onClose: () => window.location.reload(),
      });
    }
  }, [deleteActions.data]);

  useEffect(() => {
    if (error?.message === 'Authentication hook unauthorized this request') {
      onTokenSave('');
      refetch();
    }
  }, [error, onTokenSave, refetch]);

  const onSensitiveObservation = (
    observationIds: Array<number>,
    column: string,
    value: string,
  ) => {
    updateSensitiveObservation({
      variables: {
        id: observationIds,
        column,
        value,
      },
    });
  };

  const onFilterApplied = (
    whereQuery: WhereQueryProps,
    listLimit: number,
    pageNo: number,
  ) => {
    setQueryVar(whereQuery);
    setLimit(listLimit);
    setSearchParams((searchParams) => {
      searchParams.set('pageNo', String(pageNo));
      searchParams.set('limit', String(listLimit));
      searchParams.set('filter', JSON.stringify(whereQuery));

      return searchParams;
    });
  };

  if (loading) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  return (
    <ObservationsTable
      hideCameraColumn={hideCameraColumn}
      variant={variant}
      showScenarioFilters
      showCameraFilters={!isDefined(cameraId)}
      hideDateFilter={isDefined(timePeriod)}
      assignee={assignee}
      observations={observations}
      filter={filter}
      limit={limit}
      pageNumber={pageNumber}
      totalPages={totalPages}
      isOrder={isOrder}
      setIsOrder={setIsOrder}
      onSensitiveObservation={onSensitiveObservation}
      onFilterApplied={onFilterApplied}
      onUpdateObservation={onUpdateObservation}
      onDeleteObservation={onDeleteObservation}
      loading={loading}
      startingObservationUnix={moment().unix()}
    />
  );
}
