import { gql } from '@apollo/client';

const FRAGMENTS = gql`
  fragment CameraFragment on cameras {
    id
    name
    status
    image_url
    legacy_id
    cameras_scenarios {
      id
      perimeter
      customer_scenario_label {
        id
        name
      }
    }
    observations_aggregate {
      aggregate {
        count
      }
    }
    should_collect_data
    schedule

    ip_address
    port
    threshold
  }
`;

export const GET_CAMERA = gql`
  ${FRAGMENTS}

  query GET_CAMERA($id: Int!) {
    cameras_by_pk(id: $id) {
      ...CameraFragment
    }
  }
`;

export const GET_CAMERAS = gql`
  ${FRAGMENTS}

  query GET_CAMERAS {
    cameras {
      ...CameraFragment
    }
  }
`;
