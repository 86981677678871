import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { CustomButton } from '../elements/CustomButton';
import ImageViewer from '../elements/ImageViewer';

interface Props {
  imageUrl: string;
  showModal: boolean;
  onCloseClick: () => void;
}

const ShowImageModal: FC<Props> = function ShowImageModal({
  showModal,
  imageUrl,
  onCloseClick,
}) {
  const { theme } = useThemeContext();

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal observation-image danger-zone-modal"
      show={showModal}
      onHide={() => onCloseClick()}
    >
      <Modal.Header>
        <Modal.Title>{/* {title} */}</Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          // variant="no-background"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body>
        <ImageViewer imageUrl={imageUrl} />
      </Modal.Body>
    </Modal>
  );
};

export default ShowImageModal;
