/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import {
  Scenario,
  ScenarioName,
} from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { useTracker } from '../../../utils/Tracker';

type Props = {
  payload?: Payload[];
  onScenarioClick: (scenario: Scenario) => void;
  hiddenScenarios: Set<ScenarioName>;
};

export function ScenariosLegend({
  payload = [],
  onScenarioClick,
  hiddenScenarios,
}: Props) {
  const { theme } = useThemeContext();
  const { getScenariosByName } = useScenarioContext();
  const { trackScenarioTagClick } = useTracker();

  const scenarioNames = payload.map((item) => item.dataKey) as ScenarioName[];

  const scenarios = getScenariosByName(scenarioNames);

  return (
    <div
      className="d-flex justify-content-center mt-12"
      style={{
        flexFlow: 'row wrap',
        alignItems: 'flex-end',
        height: '2.25rem',
      }}
    >
      {scenarios.map((scenario) => (
        <span
          key={scenario.value}
          className="scenario-list d-flex mx-2 mt-12 add-cursor"
          aria-hidden
          onClick={() => {
            onScenarioClick(scenario);
            trackScenarioTagClick('Stacked Bar Chart Toggle Visibility', {
              title: scenario.value,
            });
          }}
          style={{
            opacity: hiddenScenarios.has(scenario.value) ? 0.4 : 1.0,
            backgroundColor:
              theme === 'light' ? scenario.colorLight : scenario.color,
            color: theme === 'light' ? scenario.color : '#fff',
          }}
        >
          {`${i18n.t(scenario.title)}`}
        </span>
      ))}
    </div>
  );
}
