import { ScenarioName } from './scenario';

export type TimeLine = {
  timestamp: number;
  message: string;
};

export type Comments = {
  timestamp: number;
  comment: string;
  sender: string;
};

export type ZoneSizeProp = {
  width: number;
  height: number;
};

export type DetectionsProp = {
  label: string;
  id: number;
  location: Array<number>;
  observation: {
    customer_scenario_label_id: number;
    __typename?: 'observations';
  };
  __typename?: 'detections';
};

export type ObservationProp = {
  id: number;
  imageUrl: string;
  priority: string;
  requestId: string;
  responder: string;
  status: string;
  system_timestamp: number;
  created: number;
  detections: Array<DetectionsProp>;
  customer_scenario_label: {
    name: ScenarioName;
    scenario: {
      name: string;
      __typename?: 'scenarios';
    };
    __typename?: 'customer_scenario_label';
  };
  camera: {
    name: string;
    __typename?: 'cameras';
  };
  observation_users: Array<{ id: number }>;
  timeline?: Array<TimeLine>;
  comments?: Array<Comments>;
  is_false_positive?: boolean;
  __typename?: 'observations';
};

export type CoordinatesProp = {
  imageUrl: string;
  keypoints?: [];
};

export type AnalyticsProps = {
  heatmap_image_url: string;
  total_observation_previous_period: number;
  last_five_observations: Array<ObservationProp>;
  total_observation_count: number;
  message: string;
};

export type TitleAndValue = {
  title: string;
  value: string | number | boolean;
};

export const OBSERVATION_PRIORITIES = [
  { title: 'priority.high', value: 'high' },
  { title: 'priority.medium', value: 'medium' },
  { title: 'priority.low', value: 'low' },
] as const;

export const OBSERVATION_STATUSES = [
  { title: 'status.open', value: 'open' },
  { title: 'status.acknowledged', value: 'acknowledged' },
  { title: 'status.resolve', value: 'resolve' },
  { title: 'status.resolve', value: 'resolved' },
] as const;

export const OBSERVATION_REPORT_REASONS = [
  // { title: 'status.open', value: 'open' },
  { title: 'button.incorrect_detection', value: 'incorrect_detection' },
  { title: 'button.other', value: 'other' },
] as const;

export function updatedAnnotationPointer(
  detection: DetectionsProp,
  zoneSize: ZoneSizeProp,
) {
  const detectionSecondItem = detection.location;
  const flattenedPoints = detectionSecondItem?.flat() ?? [];

  function getMultipliedOfPositions(partialValue: number, totalValue: number) {
    return partialValue * totalValue;
  }

  const detectionPoints: Array<number> = flattenedPoints.map(
    (value: number, index: number) => {
      if (index % 2) {
        return getMultipliedOfPositions(value, zoneSize.height);
      }
      return getMultipliedOfPositions(value, zoneSize.width);
    },
  );
  const boxPoints = {
    scenario: detection.observation.customer_scenario_label_id,
    annotations: [
      detectionPoints[0],
      detectionPoints[1],
      detectionPoints[2],
      detectionPoints[1],
      detectionPoints[2],
      detectionPoints[3],
      detectionPoints[0],
      detectionPoints[3],
    ],
  };

  return boxPoints;
}
