import React, { useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CompressObservationsTable } from './CompressObservationsTable';
import { ObservationDetailView } from './ObservationDetailView';
import { ObservationsModalImage } from './ObservationsModalImage';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { spacing } from '../../../scss/spacing';
import { ObservationProp } from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';
import { CustomSwitch } from '../../elements/CustomSwitch';
import ImageDownloader from '../../elements/ImageDownloader';
import { AnnotationRef } from '../../elements/ImageWithAnnotation';

type Props = {
  isAnnotation: boolean;
  observation: ObservationProp;
  startingObservationUnix: number;
  hideLabel: boolean;
  hideHits?: boolean;
  pageNumber?: number;
  currentIndex?: number;
  setHideLabel: (value: boolean) => void;
};

export function ObservationModalExtended({
  isAnnotation,
  observation: defaultObservation,
  startingObservationUnix,
  hideLabel = false,
  hideHits = false,
  pageNumber,
  currentIndex,
  setHideLabel,
}: Props) {
  // Context
  const { theme } = useThemeContext();

  // State
  const [onObservationLoading, setOnObservationLoading] = useState(false);
  const [observation, setObservation] =
    useState<ObservationProp>(defaultObservation);

  // Ref
  const annotationRef = useRef<AnnotationRef>(null);

  const handleExport = () => {
    if (annotationRef.current) {
      annotationRef.current.downloadImage();
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: `${spacing(4)}`,
          padding: `${spacing(3)} 0`,
        }}
      >
        {isAnnotation ? (
          <CustomButton
            title={`${i18n.t('button.download')}`}
            onClick={handleExport}
            className="button-with-loader"
            variant={`outline-${theme}`}
          />
        ) : (
          <ImageDownloader
            imageUrl={observation?.imageUrl}
            className="mb-3"
            variant={`outline-${theme}`}
          />
        )}
      </div>

      <Card
        style={{ overflow: 'hidden' }}
        className={`card-${theme} border border-${theme} border-radius`}
      >
        <Card.Body className="p-0" style={{ display: 'flex' }}>
          {pageNumber && (
            <CompressObservationsTable
              observationId={observation.id}
              startingObservationUnix={startingObservationUnix}
              pageNumber={pageNumber}
              observationPosition={currentIndex || 0}
              onObservationLoading={(loading) =>
                setOnObservationLoading(loading)
              }
              onObservationSelect={(seletedObservation) =>
                setObservation(seletedObservation)
              }
            />
          )}
          {!onObservationLoading && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ObservationsModalImage
                ref={annotationRef}
                hideLabel={hideLabel}
                isAnnotation={isAnnotation || false}
                observation={observation}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: `${spacing(4)}`,
                  padding: `${spacing(4)}  ${spacing(3)}`,
                }}
              >
                <span
                  style={{ marginRight: 'auto' }}
                  className={`${!hideHits && 'd-none'}`}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {i18n.t('show_observation_modal.hint')}
                      </Tooltip>
                    }
                  >
                    <InfoIcon className="icon" />
                  </OverlayTrigger>
                </span>

                {isAnnotation && (
                  <>
                    <CustomSwitch
                      label={`${i18n.t('modal.observation.switch.show_labels')}`}
                      checked={hideLabel}
                      onChange={(e) => setHideLabel(e.currentTarget.checked)}
                    />
                    <CustomSwitch
                      label={`${i18n.t('modal.observation.switch.danger_zone')}`}
                      checked={false}
                    />
                  </>
                )}
              </div>
              <ObservationDetailView observation={observation} />
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
