/** @jsxImportSource @emotion/react */
import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Pagination, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import VisibilityObserver from 'react-visibility-observer';
import { ReactComponent as Next } from '../../../assets/icons/next.svg';
import { ReactComponent as Prev } from '../../../assets/icons/prev.svg';
import { ReactComponent as Arrow } from '../../../assets/icons/up-arrow.svg';
import { ReactComponent as VerticalDotsIcon } from '../../../assets/icons/vertical-dots.svg';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { UPDATE_OBSERVATION_BY_ID } from '../../../graphql/mutations/observation';
import { GET_OBSERVATIONS } from '../../../graphql/queries/observation';
import { useTableAction } from '../../../hooks/table-actions';
import { GRAY_SUBTLE } from '../../../scss/colors';
import { spacing } from '../../../scss/spacing';
import { ObservationProp } from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';
import CustomDropdownWithSubMenu from '../../elements/CustomDropdownWithSubMenu';
import { LazyLoadedImage } from '../../elements/LazyLoadedImage';

type Props = {
  pageNumber: number;
  observationPosition: number;
  observationId: number;
  startingObservationUnix: number;
  order?: boolean;
  onOrderChange?: React.Dispatch<React.SetStateAction<boolean>>;
  onObservationLoading?: (loading: boolean) => void;
  onObservationSelect?: (observation: ObservationProp) => void;
};

export function CompressObservationsTable({
  pageNumber,
  observationPosition,
  observationId,
  startingObservationUnix,
  order = false,
  onOrderChange,
  onObservationLoading,
  onObservationSelect,
}: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { dropdownActionList } = useTableAction();

  const [totalPages, setTotalPage] = useState(0);
  const [firstTimeLoad, setFirstTimeLoad] = useState(false);

  const limit = 5;

  const calculatePageNumber = (currentIndex: number) => {
    const itemsPerPage = limit;
    const position = Math.floor(currentIndex / itemsPerPage) + 1;
    return position === 1 ? pageNumber * 2 - 1 : pageNumber * position;
  };
  const [convertedPageNumber, setConvertedPageNumber] = useState(
    calculatePageNumber(observationPosition),
  );
  const offset = (convertedPageNumber - 1) * limit;

  const queryVariables = {
    limit,
    offset,
    orderBy: order ? 'asc' : 'desc',
    where: {
      is_false_positive: { _eq: false },
      system_timestamp: { _lte: startingObservationUnix },
    },
  };

  const { loading, data, refetch } = useQuery(GET_OBSERVATIONS, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  });
  const [updateSensitiveObservation] = useMutation(UPDATE_OBSERVATION_BY_ID);

  const dropdownList = dropdownActionList.map((item) => ({
    ...item,
    title: i18n.t(`${item.title}`),
  }));

  const tdStyle: React.CSSProperties = {
    backgroundColor: 'transparent',
  };

  useEffect(() => {
    if (onObservationLoading) {
      onObservationLoading(loading);
    }
    if (data) {
      setTotalPage(
        Math.ceil(data.observations_aggregate.aggregate.count / limit),
      );
      setFirstTimeLoad(true);
    }
  }, [data, loading, onObservationLoading]);

  useEffect(() => {
    refetch();
    if (data && onObservationSelect && firstTimeLoad) {
      onObservationSelect(data.observations[0]);
    }
  }, [convertedPageNumber, refetch, data]);

  function getObservationIndex() {
    return data.observations.findIndex(
      (observation: ObservationProp) => observation.id === observationId,
    );
  }

  const handlePageChange = (newPageNumber: number) => {
    setConvertedPageNumber(newPageNumber);
  };

  const handleObservationSelect = (newIndex: number) => {
    if (onObservationSelect) {
      onObservationSelect(data.observations[newIndex]);
    }
  };

  const handleSensitiveObservation = (
    observationIds: Array<number>,
    column: string,
    value: string,
  ) => {
    updateSensitiveObservation({
      variables: {
        id: observationIds,
        column,
        value,
      },
    });
  };

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      const currentIndex = getObservationIndex();
      const isFirstObservation = currentIndex === 0;
      const isLastObservation = currentIndex === data.observations.length - 1;
      event.preventDefault();
      switch (event.key) {
        case 'ArrowRight':
          if (isLastObservation) {
            if (convertedPageNumber < totalPages) {
              handlePageChange(convertedPageNumber + 1);
            }
          } else {
            handleObservationSelect(currentIndex + 1);
          }
          break;
        case 'ArrowLeft':
          if (isFirstObservation) {
            if (convertedPageNumber > 1) {
              handlePageChange(convertedPageNumber - 1);
            }
          } else {
            handleObservationSelect(currentIndex - 1);
          }
          break;
        case 'F':
          break;
        case 'A':
          handleSensitiveObservation([observationId], 'status', 'acknowledged');
          break;
        case 'R':
          handleSensitiveObservation([observationId], 'status', 'resolve');
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [data, onObservationSelect]);

  return (
    <div
      className={`border-${theme}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRight: '1px solid',
      }}
    >
      <Table
        responsive={`sm alerts-table border-${theme}`}
        style={{
          borderBottom: '1px solid',
        }}
        hover
        variant={theme}
      >
        <thead className="weight-500">
          <tr>
            <td>{i18n.t('td.snapshot')}</td>
            <td>
              <div
                onClick={() => onOrderChange && onOrderChange(!order)}
                aria-hidden
                className="d-flex align-items-center add-cursor"
              >
                {i18n.t('td.date')}
                <Arrow className={`ms-1 icon ${!order && 'rotate-180'}`} />
              </div>
            </td>
            <td>{i18n.t('td.action')}</td>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.observations.map((observation: ObservationProp) => {
              const isActive = observation.id === observationId;

              return (
                <tr
                  key={observation.id}
                  style={{ backgroundColor: isActive ? GRAY_SUBTLE : 'white' }}
                  onClick={() =>
                    onObservationSelect && onObservationSelect(observation)
                  }
                >
                  <td style={tdStyle}>
                    <VisibilityObserver triggerOnce threshold={0.2}>
                      <LazyLoadedImage observation={observation} />
                    </VisibilityObserver>
                  </td>

                  <td style={tdStyle} className="p-0 ph-no-capture">
                    {moment
                      .unix(observation.system_timestamp)
                      .format('DD MMM, HH:mm:ss')}
                  </td>
                  <td style={tdStyle}>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      aria-hidden
                      onClick={(e) => e.stopPropagation()}
                    >
                      <CustomDropdownWithSubMenu
                        mainClassName="position-absolute"
                        dropdownData={dropdownList}
                        hideContextData="ph-no-capture"
                        isThreeDot
                        icon={VerticalDotsIcon}
                        variant={`outline-${theme}`}
                        bodyVariant={theme}
                        onClick={(selectValue) => {
                          const { value, subMenu } = selectValue;
                          const { id } = observation;
                          if (selectValue.value === 'view_details') {
                            navigate(`/observation/${id}`);
                          } else {
                            handleSensitiveObservation(
                              [id],
                              String(value),
                              String(subMenu ? subMenu[0].value : ''),
                            );
                          }
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div
        style={{
          borderTop: '1px solid',
          display: 'flex',
          alignItems: 'center',
          gap: spacing(3),
          padding: `${spacing(4)}  ${spacing(3)}`,
        }}
        className={`border-${theme}`}
        css={css`
          flex-wrap: wrap;
          justify-content: space-between;

          @media (max-width: 768px) {
            justify-content: flex-start;
          }
        `}
      >
        <p className="m-0">1-{totalPages} on the page</p>

        <div>
          <Pagination
            className={`custom-pagination custom-pagination-${theme} pagination-${theme}`}
            css={css`
              .page-link {
                padding: 0px !important;
                width: 18px !important;
                height: 18px !important;
              }
            `}
          >
            <Pagination.Prev
              className="page-item-prev icon p-0"
              disabled={convertedPageNumber === 1}
              onClick={() => {
                setConvertedPageNumber(convertedPageNumber - 1);
              }}
            >
              <Prev />
            </Pagination.Prev>
            <Pagination.Item active>{convertedPageNumber}</Pagination.Item>
            <Pagination.Next
              className="page-item-next icon"
              disabled={convertedPageNumber === totalPages}
              onClick={() => {
                setConvertedPageNumber(convertedPageNumber + 1);
              }}
            >
              <Next />
            </Pagination.Next>
          </Pagination>
        </div>
      </div>
    </div>
  );
}
