/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */

import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Badge, FormCheck, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FilterModal } from './FilterModal';
import { ObservationItem } from './ObservationItem';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as LargeDeleteIcon } from '../../assets/icons/large-delete.svg';
import { ReactComponent as Arrow } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { ObservationsSetInput } from '../../graphql/mutations/groups';
import {
  ADD_OBSERVATION_BOOKMARKS,
  ObservationUserInsertInput,
} from '../../graphql/mutations/observation';
import { WhereQueryProps } from '../../graphql/queries/observation';
import { useTableAction } from '../../hooks/table-actions';
import {
  defaultModalValue,
  ModalType,
} from '../../typescript/components/modal';
import { ObservationProp } from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { isNumber } from '../../utils/typeUtils';
import EmptyBookmarkList from '../default-component/EmptyBookmarkList';
import { EmptyCameraObservations } from '../default-component/EmptyCameraObservations';
import EmptyObservationsList from '../default-component/EmptyObservationsList';
import CSVDownloader from '../elements/CSVDownloader';
import { CustomButton } from '../elements/CustomButton';
import CustomDropdown from '../elements/CustomDropdown';
import CustomDropdownWithSubMenu from '../elements/CustomDropdownWithSubMenu';
import CustomPagination from '../elements/CustomPagination';
import ConfirmationModal from '../modal/ConfirmationModal';
import { ObservationModal } from '../modal/ObservationModal';
import ReportFalseModal from '../modal/ReportFalseModal';

const PAGE_LIMIT_CHOICES = [10, 20, 50, 100, 200];

type Props = {
  observations: ObservationProp[];
  filter: WhereQueryProps;
  limit: number;
  pageNumber: number;
  totalPages: number;
  loading?: boolean;
  assignee?: string;

  selectedCameraIds?: Array<number> | undefined;
  selectedScenariosIds?: Array<number> | undefined;
  dayStart?: number;
  dayEnd?: number;

  startingObservationUnix?: number;

  hideCameraColumn?: boolean;
  variant?: 'default' | 'bookmark' | 'camera';

  showCameraFilters?: boolean;
  showScenarioFilters?: boolean;
  hideDateFilter?: boolean;
  isOrder: boolean;
  setIsOrder: React.Dispatch<React.SetStateAction<boolean>>;

  onSensitiveObservation: (
    observationIds: Array<number>,
    column: string,
    value: string,
  ) => void;
  onFilterApplied: (
    filter: WhereQueryProps,
    listLimit: number,
    pageNo: number,
  ) => void;
  onUpdateObservation: (
    observationIds: Array<number>,
    value: ObservationsSetInput,
  ) => void;
  onDeleteObservation: (observationIds: Array<number>) => void;
};

export function ObservationsTable({
  observations,
  loading = true,
  assignee,
  filter,
  limit,
  pageNumber,
  totalPages,
  startingObservationUnix,
  selectedCameraIds,
  selectedScenariosIds,
  dayStart = 0,
  dayEnd = 0,
  hideCameraColumn = false,
  variant = 'default',
  showCameraFilters = false,
  showScenarioFilters = false,
  hideDateFilter = false,
  isOrder,
  setIsOrder,
  onSensitiveObservation,
  onFilterApplied,
  onUpdateObservation,
  onDeleteObservation,
}: Props) {
  // Context
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { user } = useAuthContext();
  const { listOfUser, dropdownFilteredList, dropdownActionList } =
    useTableAction();
  const [checkedObservations, setCheckedObservations] = useState<
    Array<ObservationProp>
  >([]);
  const [selectedObservation, setSelectedObservation] =
    useState<ObservationProp>();
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [checked, setChecked] = useState(false);

  // Show Observation
  const [showModal, setShowModal] = useState(false);

  // Confirmation Modal
  const [confirmationModal, setConfirmationModal] =
    useState<ModalType>(defaultModalValue);
  const [reportFalseModal, setReportFalseModal] =
    useState<ModalType>(defaultModalValue);
  const [buttonName, setButtonName] = useState<string | null>(null);

  // Filter Modal
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  // Pagination
  const handleClose = () => {
    setSelectedObservation(undefined);
    setSelectedIndex(undefined);
    setShowModal(false);
  };

  const [addObservationBookmarks, observationBookmarks] = useMutation(
    ADD_OBSERVATION_BOOKMARKS,
  );
  const observationIds = checkedObservations.map(
    (observation) => observation.id,
  );

  useEffect(() => {
    if (observationBookmarks.error) {
      toast.error(observationBookmarks.error.message);
    } else if (observationBookmarks.data) {
      toast.success(i18n.t('toast.success.bookmarked'), {
        autoClose: 500,
        onClick: () => {
          navigate('/observations?type=2');
          window.location.reload();
        },
      });
    }
  }, [observationBookmarks, observations, navigate]);

  function onBookmarks(value: Array<ObservationUserInsertInput>) {
    if (variant === 'bookmark') {
      addObservationBookmarks({
        variables: {
          data: value,
        },
      });
    }
  }

  const handleShowConfirmation = (isTrue: boolean) => {
    if (isTrue) {
      switch (buttonName) {
        case 'report':
          onUpdateObservation(observationIds, { is_false_positive: true });
          break;
        case 'delete':
          onDeleteObservation(observationIds);
          break;
        case 'acknowledged':
          onSensitiveObservation(observationIds, 'status', 'acknowledged');
          break;
        case 'resolve':
          onSensitiveObservation(observationIds, 'status', 'resolve');
          break;
        default:
          break;
      }
    }
    setReportFalseModal(defaultModalValue);
    setConfirmationModal(defaultModalValue);
  };

  const showDelete =
    user?.customer.feature_flags.observations?.show_delete || false;
  const showExportCsv =
    user?.customer.feature_flags.observations?.show_export_csv || false;

  return (
    <>
      <div className={`d-flex mb-24 action-header ${theme}`}>
        <CustomButton
          title={i18n.t('button.report_false')}
          className="button-with-loader"
          variant={`outline-${theme}`}
          icon={WarningIcon}
          type="button"
          disabled={checkedObservations.length === 0 || loading}
          loading={buttonName === 'report' && loading}
          onClick={() => {
            setButtonName('report');
            setReportFalseModal({
              title: i18n.t('button.report_false'),
              body: '',
              buttonType: 'primary',
              doneText: i18n.t('button.send'),
              cancelText: i18n.t('button.cancel'),
              show: true,
            });
          }}
        />

        <CustomDropdownWithSubMenu
          dropdownData={dropdownFilteredList}
          variant={`outline-${theme}`}
          bodyVariant={theme}
          mainClassName="position-absolute"
          hideContextData="ph-no-capture"
          className="ms-12"
          title={`${i18n.t('button.actions')}`}
          disabled={observationIds.length === 0}
          onClick={(selectValue) => {
            const { value, subMenu } = selectValue;
            if (value === 'bookmark') {
              const ids = observationIds.map((item) => ({
                observation_id: `${item}`,
                user_id: user?.id || 0,
              }));
              onBookmarks(ids);
            } else if (value === 'responder') {
              const responder = listOfUser.filter(
                (item) =>
                  item.value === String(subMenu ? subMenu[0].value : ''),
              );
              onSensitiveObservation(observationIds, value, responder[0].value);
            } else {
              onSensitiveObservation(
                observationIds,
                String(value),
                String(subMenu ? subMenu[0].value : ''),
              );
            }
          }}
        />

        {showDelete && (
          <CustomButton
            title={i18n.t('button.delete')}
            className="button-with-loader ms-2"
            variant={`outline-${theme}`}
            icon={DeleteIcon}
            type="button"
            disabled={checkedObservations.length === 0 || loading}
            loading={buttonName === 'delete' && loading}
            onClick={() => {
              setButtonName('delete');
              setConfirmationModal({
                title: `${i18n.t('landing.modal.delete_observation.title')}`,
                body: `${i18n.t('landing.modal.delete_observation.body')}`,
                buttonType: 'danger',
                doneText: i18n.t('modal.delete_observation.yes'),
                cancelText: i18n.t('modal.delete_observation.no'),
                show: true,
                icon: LargeDeleteIcon,
              });
            }}
          />
        )}
        <div className="filter-button ms-auto">
          <CustomButton
            title={i18n.t('button.filters')}
            className="button-with-loader icon"
            variant={`outline-${theme}`}
            type="button"
            icon={FilterIcon}
            onClick={() => setShowFilterModal(!showFilterModal)}
          />
          {Object.keys(filter).length !== 0 && (
            <Badge className="rounded-circle" bg="danger">
              {Object.keys(filter).length}
            </Badge>
          )}
        </div>

        {showExportCsv && (
          <CSVDownloader
            disable={observations.length === 0}
            scenarios={
              selectedScenariosIds || filter.customer_scenario_label_id?._in
            }
            responder={
              filter.responder?._eq
                ? [filter.responder?._eq]
                : assignee
                  ? [assignee]
                  : undefined
            }
            priority={filter.priority?._eq ? [filter.priority?._eq] : undefined}
            status={filter.status?._eq ? [filter.status?._eq] : undefined}
            camera_ids={selectedCameraIds || filter.camera_id?._in}
            start_time={dayStart || filter.system_timestamp?._gte}
            end_time={dayEnd || filter.system_timestamp?._lte}
            is_bookmarked={variant === 'bookmark'}
          />
        )}
      </div>
      {observations.length === 0 && (
        <div>
          {variant === 'camera' && <EmptyCameraObservations />}
          {variant === 'bookmark' && (
            <EmptyBookmarkList key="empty-list" className="pt-32" />
          )}
          {variant === 'default' && (
            <EmptyObservationsList key="empty-list" className="pt-32" />
          )}
        </div>
      )}
      {observations.length !== 0 && (
        <>
          <Table
            responsive={`sm alerts-table overflow-hidden border border-${theme} border-radius`}
            hover
            variant={theme}
          >
            <thead className="weight-500">
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <FormCheck
                      checked={checked}
                      className="me-12 check-box"
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setCheckedObservations(observations);
                        } else {
                          setCheckedObservations([]);
                        }
                        setChecked(e.currentTarget.checked);
                      }}
                    />
                    <div
                      onClick={() => {
                        setIsOrder(!isOrder);
                      }}
                      aria-hidden
                      className="d-flex align-items-center add-cursor"
                    >
                      {i18n.t('td.date')}
                      <Arrow
                        className={`ms-1 icon ${!isOrder && 'rotate-180'}`}
                      />
                    </div>
                  </div>
                </td>
                <td>{i18n.t('td.scenario')}</td>
                {!hideCameraColumn && <td>{i18n.t('td.camera_name')}</td>}
                <td>{i18n.t('td.assignee')}</td>
                <td>{i18n.t('td.status')}</td>
                <td>{i18n.t('td.snapshot')}</td>
                <td>{i18n.t('td.priority')}</td>
                <td>{i18n.t('td.action')}</td>
              </tr>
            </thead>
            <tbody>
              {observations.map(
                (observation: ObservationProp, index: number) => (
                  <ObservationItem
                    hideCameraColumn={hideCameraColumn}
                    selectedObservations={
                      checkedObservations.filter(
                        (item) => item.id === observation.id,
                      ).length !== 0
                    }
                    isObservationSelected={(selected: boolean, id: number) => {
                      if (selected) {
                        setCheckedObservations([
                          ...checkedObservations,
                          observation,
                        ]);
                      } else {
                        const withoutEl = checkedObservations?.filter(
                          (elemet) => elemet.id !== id,
                        );
                        setCheckedObservations(withoutEl);
                        setChecked(false);
                      }
                    }}
                    onImageClick={(value) => {
                      setSelectedObservation(value);
                      setSelectedIndex(index);
                      setShowModal(true);
                    }}
                    key={observation.id}
                    observation={observation}
                    dropdownActionList={dropdownActionList}
                    onSensitiveObservationAction={(id, actionType, value) => {
                      onSensitiveObservation([id], actionType, value);
                    }}
                    onReportFalseModal={(id) => {
                      setCheckedObservations(
                        observations.filter((item) => item.id === id),
                      );
                      setButtonName('report');
                      setReportFalseModal({
                        title: i18n.t('button.report_false'),
                        body: '',
                        buttonType: 'primary',
                        doneText: i18n.t('button.send'),
                        cancelText: i18n.t('button.cancel'),
                        show: true,
                      });
                    }}
                  />
                ),
              )}
            </tbody>
          </Table>
          <div className="table-bottom-actions">
            <CustomDropdownWithSubMenu
              disabled={observationIds.length === 0}
              dropdownData={dropdownFilteredList}
              mainClassName="position-absolute"
              variant={`outline-${theme}`}
              title={`${i18n.t('button.actions')}`}
              onClick={(selectValue) => {
                const { value, subMenu } = selectValue;
                onSensitiveObservation(
                  observationIds,
                  String(value),
                  String(subMenu ? subMenu[0].value : ''),
                );
              }}
            />
            <CustomPagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onPageChange={(pageNo: number) => {
                onFilterApplied(filter, limit, pageNo);
              }}
            />

            <CustomDropdown
              title={`${i18n.t('button.show_number_of_items').replace('{value}', String(limit))}`}
              dropdownData={PAGE_LIMIT_CHOICES.map((item: number) => ({
                title: item,
                value: item,
              }))}
              variant={`outline-${theme}`}
              bodyVariant={theme}
              onClick={(item) => {
                onFilterApplied(filter, Number(item.value), 1);
              }}
            />
          </div>
        </>
      )}

      <FilterModal
        filters={filter}
        showModal={showFilterModal}
        showCameraFilters={showCameraFilters}
        showScenarioFilters={showScenarioFilters}
        hideDateFilter={hideDateFilter}
        onClose={() => setShowFilterModal(!showFilterModal)}
        onApply={(appliedFilters) => {
          onFilterApplied(appliedFilters, limit, 1);
        }}
      />

      <ReportFalseModal
        modalValue={reportFalseModal}
        onCloseClick={(e) => handleShowConfirmation(e)}
      />

      <ConfirmationModal
        modalValue={confirmationModal}
        onCloseClick={(e) => handleShowConfirmation(e)}
      />
      {selectedObservation !== undefined && (
        <ObservationModal
          startingObservationUnix={startingObservationUnix}
          pageNumber={pageNumber}
          observation={selectedObservation}
          showModal={showModal}
          onCloseClick={handleClose}
          onNextPress={() => {
            const lastIndex = observations.length - 1;
            if (isNumber(selectedIndex)) {
              if (selectedIndex < lastIndex) {
                setSelectedObservation(observations[selectedIndex + 1]);
                setSelectedIndex(selectedIndex + 1);
              } else {
                handleClose();
              }
            }
          }}
          onBackPress={() => {
            if (isNumber(selectedIndex) && selectedIndex > 0) {
              setSelectedObservation(observations[selectedIndex - 1]);
              setSelectedIndex(selectedIndex - 1);
            }
          }}
          onFKeyPress={() => {
            setCheckedObservations([selectedObservation]);
            setButtonName('report');
            setReportFalseModal({
              title: i18n.t('button.report_false'),
              body: '',
              buttonType: 'primary',
              doneText: i18n.t('button.send'),
              cancelText: i18n.t('button.cancel'),
              show: true,
            });
          }}
          onAKeyPress={() => {
            setCheckedObservations([selectedObservation]);
            setButtonName('acknowledged');
            setConfirmationModal({
              title: `${i18n.t('confirmation.modal.acknowledged_observation.title')}`,
              body: `${i18n.t('confirmation.modal.acknowledged_observation.body')}`,
              buttonType: 'primary',
              doneText: i18n.t('button.yes'),
              cancelText: i18n.t('button.back'),
              show: true,
            });
          }}
          onRKeyPress={() => {
            setCheckedObservations([selectedObservation]);
            setButtonName('resolve');
            setConfirmationModal({
              title: `${i18n.t('confirmation.modal.resolve_observation.title')}`,
              body: `${i18n.t('confirmation.modal.resolve_observation.body')}`,
              buttonType: 'primary',
              doneText: i18n.t('button.yes'),
              cancelText: i18n.t('button.back'),
              show: true,
            });
          }}
          currentIndex={selectedIndex}
          onLoading={loading}
          isAllAction
        />
      )}
    </>
  );
}
