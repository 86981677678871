import moment from 'moment';
import React, { useRef } from 'react';
import { ObservationsModalImage } from './ObservationsModalImage';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { ObservationProp } from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';
import ImageDownloader from '../../elements/ImageDownloader';
import { AnnotationRef } from '../../elements/ImageWithAnnotation';

type Props = {
  isAnnotation: boolean;
  observation: ObservationProp;
  hideLabel: boolean;
};

export function ObservationModalDefault({
  isAnnotation,
  observation,
  hideLabel = false,
}: Props) {
  // Context
  const { theme } = useThemeContext();
  const { getScenarioByName } = useScenarioContext();

  // Ref
  const annotationRef = useRef<AnnotationRef>(null);

  const scenario = getScenarioByName(observation.customer_scenario_label.name);

  const handleExport = () => {
    if (annotationRef.current) {
      annotationRef.current.downloadImage();
    }
  };

  return (
    <>
      <ObservationsModalImage
        ref={annotationRef}
        hideLabel={hideLabel}
        isAnnotation={isAnnotation || false}
        observation={observation}
      />
      <div className="d-flex justify-content-between align-items-center mt-12">
        <span className="ph-no-capture me-12">
          {moment.unix(observation.system_timestamp).format('DD MMM, HH:mm:ss')}
        </span>
        <div className="d-flex align-items-center">
          <span className="camera-name rounded-pill ph-no-capture me-12">
            {observation.camera
              ? observation.camera.name
              : i18n.t('td.camera_no_longer_exists')}
          </span>
          {!scenario ? (
            i18n.t('td.scenario_not_found')
          ) : (
            <span
              className="scenario-list"
              style={{
                backgroundColor:
                  theme === 'light' ? scenario.colorLight : scenario.color,
                color: theme === 'light' ? scenario.color : '#fff',
              }}
            >
              {i18n.t(scenario.title)}
            </span>
          )}
        </div>

        {isAnnotation ? (
          <CustomButton
            title={`${i18n.t('button.download')}`}
            onClick={handleExport}
            className="button-with-loader"
            variant={`outline-${theme}`}
          />
        ) : (
          <ImageDownloader
            imageUrl={observation?.imageUrl}
            className="mb-3"
            variant={`outline-${theme}`}
          />
        )}
      </div>
    </>
  );
}
