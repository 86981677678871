/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import { DangerZoneOverlay } from './DangerZoneOverlay';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash-can.svg';
import { useCameraContext } from '../../../../contextapi/CameraProvider';
import { useScenarioContext } from '../../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../../contextapi/ThemeProvider';
import { ScenarioPerimeter } from '../../../../hooks/graphql/camera';
import {
  getCameraScenarios,
  sortScenarios,
  supportsDangerZone,
} from '../../../../lib/helpers/scenario';
import { isPerimeter } from '../../../../typescript/camera/camera';
import { Scenario } from '../../../../typescript/observation/scenario';
import i18n from '../../../../utils/i18n';
import { CustomButton } from '../../../elements/CustomButton';

interface Props {
  show: boolean;
  onClose: () => void;
}

export function DangerZoneModal({ show, onClose }: Props) {
  const { theme } = useThemeContext();
  const { camera, gqlCamera, updateCamera } = useCameraContext();
  const { userScenarios } = useScenarioContext();

  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const supportedScenarios = sortScenarios(
    getCameraScenarios(userScenarios, gqlCamera).filter(supportsDangerZone),
  );

  const [selectedScenario, setSelectedScenario] = useState<Scenario>(
    supportedScenarios[0],
  );

  const imageRef = useRef<HTMLImageElement>(null);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [selectedZoneIndex, setSelectedZoneIndex] = useState<
    number | undefined
  >();

  const [scenarioPerimeter, setScenarioPerimeter] =
    useState<ScenarioPerimeter>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const cameraPerimeters = gqlCamera?.cameras_scenarios
      .map((item) => item.perimeter)
      .filter(isPerimeter);

    const perimeter = cameraPerimeters?.find(
      (item) => item.scenario === selectedScenario.value,
    );

    if (perimeter) {
      setScenarioPerimeter(perimeter);
    } else {
      setScenarioPerimeter({
        scenario: selectedScenario.value,
        position: [],
      });
    }
  }, [gqlCamera, selectedScenario]);

  useEffect(() => {
    const defaultScenario = supportedScenarios.at(0);
    if (defaultScenario && !selectedScenario) {
      setSelectedScenario(defaultScenario);
    }
  }, [supportedScenarios, selectedScenario]);

  const onScenarioClick = (scenario: Scenario) => {
    setSelectedScenario(scenario);
    setSelectedZoneIndex(undefined);
  };

  const handleCloseModal = () => {
    setSelectedZoneIndex(undefined);
    onClose();
  };

  const onSaveChanges = async () => {
    if (!camera) return;

    setLoading(true);

    const cameraPerimeters = (gqlCamera?.cameras_scenarios || [])
      .map((item) => item.perimeter)
      .filter(isPerimeter);

    const updatedPerimeters = [
      ...cameraPerimeters.filter(
        (item) => item.scenario !== selectedScenario?.value,
      ),
      scenarioPerimeter,
    ];

    const success = await updateCamera({
      ...camera,
      perimeters: JSON.stringify(updatedPerimeters),
    });

    setLoading(false);
    if (success) {
      handleCloseModal();
    }
  };

  const onClickDraw = () => {
    setSelectedZoneIndex(undefined);
    setIsInEditMode(true);
  };

  const onClickErase = () => {
    if (scenarioPerimeter) {
      setScenarioPerimeter({
        ...scenarioPerimeter,
        position: scenarioPerimeter.position.filter(
          (_, index) => index !== selectedZoneIndex,
        ),
      });
    }

    setSelectedZoneIndex(undefined);
  };

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal observation-image danger-zone-modal"
      show={show}
      onHide={handleCloseModal}
    >
      <Modal.Header>
        <Modal.Title>
          {i18n.t('modal.camera.edit_dangerzone.title')}
        </Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          type="button"
          onClick={handleCloseModal}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <h3>
              {selectedScenario !== null && i18n.t(selectedScenario.title)}
            </h3>
            <p>
              {selectedScenario !== null &&
                i18n.t(selectedScenario.description)}
            </p>
          </Col>
          <Col md={12} className="text-center canvas-container">
            <Image
              onLoad={() => setIsImageLoading(false)}
              onError={() => {
                setIsImageLoading(true);
              }}
              width="100%"
              src={camera?.imageUrl}
              ref={imageRef}
            />
            {!isImageLoading && selectedScenario && scenarioPerimeter && (
              <DangerZoneOverlay
                selectedScenario={selectedScenario}
                scenarioPerimeter={scenarioPerimeter}
                onClear={() => setSelectedZoneIndex(undefined)}
                onZoneSelected={(selectedZoneId) =>
                  setSelectedZoneIndex(selectedZoneId)
                }
                selectedZoneId={selectedZoneIndex}
                imageElement={imageRef.current}
                editable={isInEditMode}
                onSelectedZoneUpdated={(pointerIndex, zonePoints) => {
                  setScenarioPerimeter({
                    ...scenarioPerimeter,
                    position: scenarioPerimeter.position.map((pos, index) =>
                      index === pointerIndex ? zonePoints : pos,
                    ),
                  });
                }}
                onZoneAdded={(coordinates) => {
                  setScenarioPerimeter({
                    ...scenarioPerimeter,
                    position: [...scenarioPerimeter.position, coordinates],
                  });
                  setIsInEditMode(false);
                }}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="action-container">
          <div className="scenario-tag">
            <ul>
              {supportedScenarios.map((scenario) => (
                <li
                  className="add-cursor scenario-list ms-12 mb-12"
                  key={scenario.value}
                  aria-hidden
                  style={{
                    backgroundColor:
                      theme === 'light' ? scenario.colorLight : scenario.color,
                    color: theme === 'light' ? scenario.color : '#fff',
                    opacity:
                      selectedScenario?.value === scenario.value ? 1.0 : 0.5,
                  }}
                  onClick={() => onScenarioClick(scenario)}
                >
                  {i18n.t(scenario.title)}
                </li>
              ))}
            </ul>
          </div>

          <div className="action-div">
            <CustomButton
              variant={`outline-${theme}`}
              type="button"
              title={i18n.t('button.draw')}
              onClick={onClickDraw}
            />

            <CustomButton
              variant={`outline-${theme}`}
              type="button"
              title={i18n.t('button.erase')}
              icon={DeleteIcon}
              disabled={selectedZoneIndex === undefined}
              onClick={onClickErase}
            />
          </div>
        </div>

        <CustomButton
          title={i18n.t('button.back_to_settings')}
          variant={`outline-${theme}`}
          type="button"
          onClick={onClose}
        />

        <CustomButton
          variant="primary"
          type="button"
          title={i18n.t('button.save_changes')}
          className="button-with-loader"
          disabled={loading}
          loading={loading}
          onClick={onSaveChanges}
        />
      </Modal.Footer>
    </Modal>
  );
}
