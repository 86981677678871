import { Camera } from '../../hooks/graphql/camera';
import { Scenario, ScenarioName } from '../../typescript/observation/scenario';
import i18n from '../../utils/i18n';

const getUserScenariosByName = (
  userScenarios: Scenario[],
  scenarioNames: ScenarioName[],
) => userScenarios.filter((scenario) => scenarioNames.includes(scenario.value));

export function getCameraScenarios(userScenarios: Scenario[], camera?: Camera) {
  const scenarioNames =
    camera?.cameras_scenarios.map(
      (scenario) => scenario.customer_scenario_label.name,
    ) || [];

  return getUserScenariosByName(userScenarios, scenarioNames);
}

export function getScenarioById(scenarios: Scenario[], scenarioId?: number) {
  return scenarios.find((scenario) => scenario.id === scenarioId);
}

export const supportsDangerZone = (scenario: Scenario) => scenario.isDangerZone;

export const sortScenarios = (scenarios: Scenario[]) =>
  [...scenarios].sort((a, b) => i18n.t(a.title).localeCompare(i18n.t(b.title)));
