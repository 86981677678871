/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Image } from 'react-bootstrap';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useObservationImage } from '../../../hooks/image';
import { ObservationProp } from '../../../typescript/observation/observation';
import { ImageLoader } from '../../elements/ImageLoader';
import ImageWithAnnotation, {
  AnnotationRef,
} from '../../elements/ImageWithAnnotation';

type Props = {
  isAnnotation: boolean;
  observation: ObservationProp;
  hideLabel: boolean;
};

export const ObservationsModalImage = forwardRef<AnnotationRef | null, Props>(
  ({ isAnnotation, observation, hideLabel = false }, ref) => {
    // Context
    const { theme } = useThemeContext();
    const { isImageLoading, setIsImageLoading, getObservationImageUrl } =
      useObservationImage();

    // Ref
    const annotationRef = useRef<AnnotationRef>(null);

    // State
    const [imageURL, setImageURL] = useState<string>('');

    useImperativeHandle(ref, () => annotationRef.current!);

    useEffect(() => {
      setImageURL('');
    }, [observation]);

    async function getImageUrl() {
      if (observation) {
        const imageURL = await getObservationImageUrl(observation, 'original');
        setImageURL(imageURL);
      }
    }

    return (
      <>
        <ImageLoader
          style={{ height: 400, width: '100%' }}
          className={`loading-image loading-image-${theme} ${!isImageLoading && 'd-none'}`}
        />
        {isAnnotation ? (
          <ImageWithAnnotation
            ref={annotationRef}
            className={`ph-no-capture add-cursor ${isImageLoading && 'd-none'}`}
            containerWidth="100%"
            containerHeight="100%"
            onImageLoading={isImageLoading}
            hideLabel={hideLabel}
            isActionEnable
            onLoad={() => setIsImageLoading(false)}
            onError={async () => getImageUrl()}
            imageUrl={imageURL}
            detections={observation.detections}
          />
        ) : (
          <Image
            className={`${isImageLoading && 'd-none'}`}
            onLoad={() => setIsImageLoading(false)}
            onError={() => getImageUrl()}
            width="100%"
            height="100%"
            src={imageURL}
          />
        )}
      </>
    );
  },
);
