/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { ScenarioButton } from './ScenarioButton';
import { ReactComponent as LegendBar } from '../../../assets/icons/legend-bar.svg';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { Camera } from '../../../hooks/graphql/camera';
import {
  getCameraScenarios,
  getScenarioById,
  sortScenarios,
} from '../../../lib/helpers/scenario';
import { GRAY_MIDDLE, GRAY_WARM_DARKER_AA } from '../../../scss/colors';
import { spacing } from '../../../scss/spacing';
import { Scenario } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { isEmptyArray } from '../../../utils/typeUtils';
import { CustomSwitch } from '../../elements/CustomSwitch';
import { HeatMapImageWithDangerZone } from '../HeatMapImageWithDangerZone';
import { TitleWithContent } from '../TitleWithContent';

type Props = {
  camera: Camera;
  scenarioId?: number;
  onScenarioChange?: (scenario: Scenario) => void;
};

export function ExtendedCameraHeatMap({
  camera,
  scenarioId,
  onScenarioChange,
}: Props) {
  const { theme } = useThemeContext();
  const { userScenarios, observedScenarios } = useScenarioContext();
  const [showDangerZones, setShowDangerZones] = useState(false);

  const visibleScenarios = sortScenarios(
    Array.from(
      new Set([
        ...getCameraScenarios(userScenarios, camera),
        ...observedScenarios,
      ]),
    ),
  );

  const scenariosWithHeatmap = visibleScenarios.filter((scenario) =>
    observedScenarios.some((s) => s.id === scenario.id),
  );
  const scenariosWithoutHeatmap = visibleScenarios.filter(
    (scenario) => !observedScenarios.some((s) => s.id === scenario.id),
  );

  const selectedScenario =
    getScenarioById(scenariosWithHeatmap, scenarioId) ||
    scenariosWithHeatmap.at(0);

  const changeScenario = useCallback(
    (scenario: Scenario) => {
      if (onScenarioChange) {
        onScenarioChange(scenario);
      }
    },
    [onScenarioChange],
  );

  useEffect(() => {
    const scenario = getScenarioById(visibleScenarios, scenarioId);
    if (!scenario && !isEmptyArray(visibleScenarios)) {
      changeScenario(visibleScenarios[0]);
    }
  }, [changeScenario, scenarioId, visibleScenarios]);

  return (
    <Card className={`card-${theme} border border-${theme} border-radius`}>
      <Card.Body
        className="g-0"
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          position: 'relative',
          padding: 0,
        }}
      >
        <Col
          md={9}
          style={{
            background: 'black',
            display: 'flex',
            alignItems: 'center',
            minHeight: 470,
          }}
        >
          <HeatMapImageWithDangerZone
            camera={camera}
            scenario={selectedScenario}
            showDangerZones={showDangerZones}
          />
        </Col>
        <Col
          md={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <h3
            style={{
              height: spacing(9),
              padding: spacing(4),
              margin: 0,
              lineHeight: 'normal',
              borderBottom: `1px solid ${theme === 'light' ? GRAY_MIDDLE : GRAY_WARM_DARKER_AA}`,
            }}
          >
            {`${i18n.t('camera_detail.scenarios')} (${visibleScenarios.length})`}
          </h3>
          <div
            style={{
              overflowX: 'auto',
              flex: '1 1 0',
              marginBottom: -1,
              minHeight: 120,
            }}
          >
            {scenariosWithHeatmap.map((scenario, index) => (
              <ScenarioButton
                key={scenario.value}
                scenario={scenario}
                isSelected={scenario.id === selectedScenario?.id}
                onClick={() => changeScenario(scenario)}
                isDisabled={
                  !observedScenarios.some((s) => s.id === scenario.id)
                }
                isFirst={index === 0}
              />
            ))}
            {scenariosWithoutHeatmap.map((scenario) => (
              <ScenarioButton
                key={scenario.value}
                scenario={scenario}
                isSelected={scenario.id === selectedScenario?.id}
                onClick={() => changeScenario(scenario)}
                isDisabled={
                  !observedScenarios.some((s) => s.id === scenario.id)
                }
              />
            ))}
          </div>

          <TitleWithContent
            title={`${i18n.t('camera_detail.display_options')}`}
          >
            <CustomSwitch
              label={`${i18n.t('switch.danger_zone')}`}
              checked={showDangerZones}
              onChange={() => setShowDangerZones(!showDangerZones)}
            />
          </TitleWithContent>

          <TitleWithContent title={`${i18n.t('camera_detail.legend')}`}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className="m-0 me-12">{i18n.t('camera_detail.legend.less')}</p>
              <LegendBar />
              <p className="m-0 ms-12">{i18n.t('camera_detail.legend.more')}</p>
            </div>
          </TitleWithContent>
        </Col>
      </Card.Body>
    </Card>
  );
}
