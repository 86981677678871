/* eslint-disable no-nested-ternary */
import { useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as VerticalDotsIcon } from '../../../assets/icons/vertical-dots.svg';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { TEST_CHANNEL } from '../../../graphql/mutations/alerts';
import { ClientUser } from '../../../typescript/observation/assignee';
import {
  ActionProps,
  MultiActionProps,
} from '../../../typescript/smart-alert/smart-alert';
import i18n from '../../../utils/i18n';
import CustomDropdown from '../../elements/CustomDropdown';
import CustomDropdownWithCheckbox from '../../elements/CustomDropdownWithCheckbox';
import CustomDropdownWithSubMenu from '../../elements/CustomDropdownWithSubMenu';
import InputField from '../../elements/InputField';

interface Props {
  className?: string | undefined;
  actionList: Array<ActionProps>;
  selectedAction?: MultiActionProps;
  onAction: (action: MultiActionProps) => void;
  onDelete?: (action: MultiActionProps) => void;
}

const dropdownActionList = [
  {
    title: 'button.test',
    value: 'test',
  },
  {
    title: 'button.delete',
    value: 'delete',
  },
];

const AlertAction: FC<Props> = function AlertAction({
  className,
  actionList,
  selectedAction,
  onAction,
  onDelete,
}) {
  const { theme } = useThemeContext();
  const { clientUsersList } = useAuthContext();

  const [allUser, setAllUser] = useState<Array<ClientUser>>([]);
  const [selectedAllUser, setSelectedAllUser] = useState<boolean>(false);

  const [testObservation, { data, loading, error }] = useMutation(TEST_CHANNEL);

  const selectedUserLength = allUser.filter((item) => item.isChecked).length;

  useEffect(() => {
    if (clientUsersList && selectedAction) {
      const { userIds } = selectedAction;
      const users = clientUsersList.map((item) => ({
        ...item,
        isChecked: userIds?.some((o2) => item.id === o2),
      }));
      setAllUser(users);
    }
  }, [clientUsersList, selectedAction]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    } else if (data) {
      toast.success(i18n.t('toast.success.test'));
    }
  }, [data, error]);

  const onTestNotification = async (value: string) => {
    testObservation({
      variables: {
        channel_name: value,
      },
    });
  };

  const isUser: boolean = !selectedAction
    ? true
    : selectedAction.userIds
      ? selectedAction.userIds.length === 0
      : true;

  const isIot: boolean = !selectedAction
    ? true
    : selectedAction.iot
      ? selectedAction.iot.length === 0
      : true;

  return (
    <Col md={12} lg={7} className={className}>
      <div className="d-flex align-items-center">
        <CustomDropdown
          className="rounded-pill me-12"
          title={
            !selectedAction
              ? `${i18n.t('button.select_action')}`
              : i18n.t(
                  `actionlist.${selectedAction.action.alert_channel.alert_type_name}`,
                )
          }
          dropdownData={actionList.map((item) => ({
            title: `${i18n.t(`actionlist.${item.alert_channel.alert_type_name}`)}`,
            text: item.alert_channel.alert_type_name,
            value: item.id,
          }))}
          variant={`outline-${theme}`}
          bodyVariant={theme}
          onClick={(actionValue) => {
            const { value, text } = actionValue;
            const action = {
              alert_channel_id: Number(value),
              id: Number(value),
              alert_channel: {
                alert_type_name: String(text),
              },
            };
            const param = {
              action,
              type: text === 'iot' ? 1 : 0,
            };
            onAction(param);
          }}
        />

        {selectedAction && (
          <div className="d-flex">
            {selectedAction.type === 0 ? (
              <>
                <CustomDropdownWithCheckbox
                  loading={loading}
                  disabled={loading}
                  onSelectedAll={(value, fromChild) => {
                    if (!fromChild) {
                      const selectAll = allUser.map((item) => ({
                        ...item,
                        isChecked: value,
                      }));
                      setAllUser(selectAll);
                      const userIdList = selectAll
                        .filter((user) => user.isChecked)
                        .map((user) => user.id) as Array<number>;
                      const param = { ...selectedAction };
                      param.userIds = userIdList;
                      onAction(param);
                    }
                    setSelectedAllUser(value);
                  }}
                  selectedAll={selectedAllUser}
                  className="rounded-pill"
                  title={`${
                    selectedUserLength === 0
                      ? i18n.t('button.select_user')
                      : `${selectedUserLength} ${i18n.t('button.selected')}`
                  } `}
                  selectAllTitle={`${i18n.t('button.select_all')}`}
                  dropdownData={allUser
                    .sort((a, b) => {
                      if (a.email < b.email) {
                        return -1;
                      }
                      if (a.email > b.email) {
                        return 1;
                      }
                      return 0;
                    })
                    .filter((item) =>
                      selectedAction.action.alert_channel.alert_type_name ===
                      'sms'
                        ? item.phoneNo
                        : item,
                    )
                    .map((item) => ({
                      title: item.username
                        ? `${item.username} (${item.email})`
                        : item.email,
                      value: item.id || 0,
                      isChecked: item.isChecked,
                    }))}
                  hideContextData="ph-no-capture"
                  variant={`outline-${theme}`}
                  bodyVariant={theme}
                  onClick={(selectUser) => {
                    const selectAll = allUser.map((item) => {
                      if (item.id === selectUser.value) {
                        return {
                          ...item,
                          isChecked: !item.isChecked,
                        };
                      }
                      return item;
                    });
                    const userIdList = selectAll
                      .filter((user) => user.isChecked)
                      .map((user) => user.id) as Array<number>;
                    const param = { ...selectedAction };
                    param.userIds = userIdList;
                    onAction(param);
                  }}
                />
                <CustomDropdownWithSubMenu
                  disabled={loading}
                  mainClassName="ms-12"
                  dropdownData={dropdownActionList.map((item) => ({
                    ...item,
                    isDisable: item.value === 'test' && isUser,
                    title: `${i18n.t(item.title)}`,
                  }))}
                  hideContextData="ph-no-capture"
                  isThreeDot
                  icon={VerticalDotsIcon}
                  variant={`outline-${theme}`}
                  bodyVariant={theme}
                  onClick={(selectValue) => {
                    const { value } = selectValue;
                    if (value === 'delete') {
                      if (onDelete) {
                        onDelete(selectedAction);
                      }
                    } else {
                      onTestNotification(
                        selectedAction.action.alert_channel.alert_type_name,
                      );
                    }
                  }}
                />
              </>
            ) : (
              <>
                <InputField
                  className={`input-${theme} input-default-width`}
                  type="text"
                  controlId="formIOT"
                  placeholder={`${i18n.t('input.iot_url_placeholder')}`}
                  // required
                  value={selectedAction.iot}
                  onChange={(e) => {
                    const param = { ...selectedAction };
                    param.iot = e.target.value;
                    onAction(param);
                  }}
                />
                <CustomDropdownWithSubMenu
                  mainClassName="ms-12"
                  dropdownData={dropdownActionList.map((item) => ({
                    ...item,
                    isDisable: item.value === 'test' && isIot,
                    title: `${i18n.t(item.title)}`,
                  }))}
                  hideContextData="ph-no-capture"
                  isThreeDot
                  icon={VerticalDotsIcon}
                  variant={`outline-${theme}`}
                  bodyVariant={theme}
                  onClick={(selectValue) => {
                    const { value } = selectValue;
                    if (value === 'delete') {
                      if (onDelete) {
                        onDelete(selectedAction);
                      }
                    } else {
                      onTestNotification(
                        selectedAction.action.alert_channel.alert_type_name,
                      );
                    }
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
    </Col>
  );
};

AlertAction.defaultProps = {
  className: '',
  selectedAction: undefined,
  onDelete: undefined,
};

export default AlertAction;
