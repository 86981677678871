import {
  Camera,
  NoPerimeter,
  ScenarioPerimeter,
  XYCoordinate,
} from '../../hooks/graphql/camera';
import { isNotEmptyObject } from '../../utils/typeUtils';
import { ScenarioName } from '../observation/scenario';

export type CameraProps = {
  id: string;
  sql_id: number;
  imageUrl: string;
  name: string;
  perimeters: string;
  scenarios: ScenarioName[];
  schedule: string;
  shouldCollectData: boolean;
};

export type CameraUpdatePayload = CameraProps & {
  ipAddress: string;
  port: number;
  threshold: number;
};

export const RenderList = [
  {
    value: 2,
    colValue: 6,
  },
  {
    value: 3,
    colValue: 4,
  },
  {
    value: 4,
    colValue: 3,
  },
];

export type RenderListProps = {
  value: number;
  colValue: number;
};

export const numberOfIncident = [10, 20, 50, 100, 200];

export const showNumberOfItems = [1, 10, 20, 50, 100, 200];

export type DangerZoneResult = {
  id: number;
  completed: boolean;
  zonePoints: XYCoordinate[];
};

export const isPerimeter = (
  perimeter: ScenarioPerimeter | NoPerimeter,
): perimeter is ScenarioPerimeter => 'scenario' in perimeter;

export function transformCameraToUpdatePayload(
  camera: Camera,
): CameraUpdatePayload {
  return {
    id: String(camera.id),
    sql_id: camera.id,
    imageUrl: camera.image_url,
    name: camera.name,
    perimeters: JSON.stringify(
      camera.cameras_scenarios
        .map((item) => item.perimeter)
        .filter(isNotEmptyObject),
    ),
    schedule: JSON.stringify(camera.schedule),
    shouldCollectData: camera.should_collect_data,
    scenarios: camera.cameras_scenarios.map(
      (item) => item.customer_scenario_label.name,
    ),
    ipAddress: camera.ip_address,
    port: camera.port,
    threshold: camera.threshold,
  };
}
