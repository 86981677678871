/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CameraLink } from './CameraLink';
import { DisplayImage } from './DisplayImage';
import { streamCameras } from '../../apis/api-request';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/bar.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as WatchLiveIcon } from '../../assets/icons/signals.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
// import { CameraProps } from '../../typescript/camera/camera';
import { Camera } from '../../hooks/graphql/camera';
import { spacing } from '../../scss/spacing';
import i18n from '../../utils/i18n';
import { CameraStatusIcon } from '../camera/CameraStatusIcon';
import { CustomButton } from '../elements/CustomButton';
import { Loader } from '../elements/Loader';
import { CameraSettingsModal } from '../modal/CameraSettingsModal';

interface Props {
  cameras: Array<Camera> | undefined;
  filterBySearch: string;
  renderListCol: number;
}

export function CameraList({ cameras, filterBySearch, renderListCol }: Props) {
  const { theme } = useThemeContext();
  const { userCookie, onTokenSave, user, featureFlags } = useAuthContext();

  const [streamUrl, setStreamUrl] = useState<string | null>(null);
  const [camera, setCamera] = useState<Camera | null>(null);
  const [isStreamOpen, setIsStreamOpen] = useState<boolean>(false);
  const [onUrlLoading, setOnUrlLoading] = useState<boolean>(true);

  // Modal
  const [show, setShow] = useState<boolean>(false);

  const handleClose = async () => {
    setStreamUrl(null);
    setOnUrlLoading(true);
    setShow(false);
  };

  const handleShow = async (id: string) => {
    setShow(true);
    setIsStreamOpen(true);
    const param = { id };
    const cameraStream = await streamCameras(userCookie.userToken, param);
    setIsStreamOpen(false);
    if (cameraStream.status === 401) {
      onTokenSave('');
    }
    setStreamUrl(cameraStream.data);
  };

  const isAdmin = user?.isAdmin || false;
  const showLiveStream = featureFlags.cameras?.show_live_stream;
  const showDetailsPage = featureFlags.cameras.show_details_page;

  return (
    <Row className="camera">
      {cameras
        ?.sort((a: Camera, b: Camera) => a.name.localeCompare(b.name))
        .filter(
          (item) =>
            item.name.toLowerCase().indexOf(filterBySearch.toLowerCase()) !==
            -1,
        )
        .map((item: Camera) => (
          <Col key={item.id} md={renderListCol} className="camera-item-cell">
            <div className="camera-item-container">
              <div className="front camera-item">
                <p
                  className="camera-status rounded weight-500 ph-no-capture"
                  style={{ gap: `${spacing(2)}` }}
                >
                  <span>{item.name}</span>
                  {item.status !== null && (
                    <CameraStatusIcon status={item.status} />
                  )}
                </p>
                <DisplayImage id={item.id} cameraPerRow={renderListCol} />
              </div>

              <div className="back">
                {showDetailsPage ? (
                  <CameraLink link={`/camera/${item.id}`}>
                    {i18n.t('page.cameras.go_to_details')}
                    <ArrowRightIcon />
                  </CameraLink>
                ) : (
                  <div className="buttons">
                    <CustomButton
                      className="button-with-loader mx-12"
                      variant="transparent"
                      title={i18n.t('button.settings')}
                      type="button"
                      icon={SettingsIcon}
                      disabled={!isAdmin}
                      onClick={() => {
                        setCamera(item);
                      }}
                    />

                    {showLiveStream && (
                      <CustomButton
                        className="button-with-loader mx-12"
                        variant="transparent"
                        title={i18n.t('button.watch_live')}
                        type="button"
                        icon={WatchLiveIcon}
                        onClick={() => handleShow(item.legacy_id)}
                      />
                    )}

                    <Link
                      to={`/camera-analytics/${item.id}`}
                      className="me-2 btn btn-transparent"
                    >
                      <AnalyticsIcon />
                      {i18n.t('button.analytics')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Col>
        ))}
      <Modal
        centered
        contentClassName={theme}
        className="custom-modal observation-image"
        show={show}
        onHide={() => handleClose()}
      >
        <Modal.Header>
          <Modal.Title>{/* {title} */}</Modal.Title>
          <CustomButton
            icon={CancelIcon}
            className="border-0"
            // variant="no-background"
            type="button"
            onClick={() => handleClose()}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="text-center live-camera">
              {!isStreamOpen && (
                <Image
                  onError={() => {
                    handleClose();
                    if (streamUrl) {
                      toast.error(`${i18n.t('error.all_camera')}`);
                    }
                  }}
                  onLoad={() => setOnUrlLoading(false)}
                  width="100%"
                  src={`${streamUrl}`}
                />
              )}
              {onUrlLoading && <Loader main />}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {camera && (
        <CameraSettingsModal
          cameraId={camera.id}
          show
          onCloseClick={() => setCamera(null)}
        />
      )}
    </Row>
  );
}
