import moment from 'moment';
import React, { FC } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import { ReactComponent as VerticalDotsIcon } from '../../assets/icons/vertical-dots.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { RuleProps } from '../../typescript/smart-alert/old-smart-alert';
import i18n from '../../utils/i18n';

interface Props {
  alert: RuleProps;
  onEdit?: React.MouseEventHandler<HTMLElement> | undefined;
  onDelete?: React.MouseEventHandler<HTMLElement> | undefined;
}

const OldRuleItem: FC<Props> = function RuleItem({ alert, onDelete, onEdit }) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();
  const { cameraList } = useCameraContext();

  const filteredCamera = cameraList.filter(
    (item) => alert.trigger.cameras.indexOf(`${item.sql_id}`) !== -1,
  );
  const filteredScenario = scenariosList.filter(
    (scenario) => scenario.id === Number(alert.trigger.scenarios[0]),
  );

  return (
    <tr onClick={onEdit} className="add-cursor">
      <td>
        <div className="alert-rule">
          <div
            className={`rounded-circle border border-${theme} border-radius p-2 me-12`}
          >
            <BellIcon className="m-0 icon" />
          </div>
          <div>
            <p className="mb-1 weight-600 ph-no-capture">{alert.title}</p>
            <p className="sub-color ph-no-capture">
              {alert.last_triggered_timestamp !== null
                ? `${i18n.t('alert.timestamp.triggered')} ${moment.unix(alert.last_triggered_timestamp).fromNow()}`
                : i18n.t('alert.timestamp.not_triggered')}
            </p>
          </div>
        </div>
      </td>
      <td>
        {alert.trigger.scenarios.length !== 0 && !filteredScenario[0] ? (
          i18n.t('td.scenario_not_found')
        ) : (
          <span
            className="scenario-list ph-no-capture"
            style={{
              backgroundColor:
                theme === 'light'
                  ? filteredScenario[0].colorLight
                  : filteredScenario[0].color,
              color: theme === 'light' ? filteredScenario[0].color : '#fff',
            }}
          >
            {i18n.t(filteredScenario[0].title)}
          </span>
        )}
      </td>
      <td>
        {filteredCamera.slice(0, 4).map((item) => (
          <span
            key={item.id}
            className="camera-name rounded-pill ph-no-capture"
          >
            {item.name}
          </span>
        ))}

        {filteredCamera.length > 4 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                <span className="ph-no-capture">
                  {filteredCamera
                    .slice(4, filteredCamera.length - 1)
                    .map((item) => item.name)
                    .toString()
                    .replaceAll(',', ', ')}
                </span>
              </Tooltip>
            }
          >
            <span className="show-more-cameras ph-no-capture">
              {filteredCamera.slice(4, filteredCamera.length - 1).length}{' '}
              {i18n.t('list.others')}
            </span>
          </OverlayTrigger>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Dropdown
            className="three-dot-dropdown position-absolute"
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown.Toggle
              className="rounded-circle button-with-loader p-0"
              style={{ width: 40, height: 40 }}
              variant={`outline-${theme} text-capitalize me-2`}
              id="dropdown-basic"
            >
              <VerticalDotsIcon className="m-0 icon" />
            </Dropdown.Toggle>

            <Dropdown.Menu variant={theme}>
              <Dropdown.Item onClick={onEdit}>
                {i18n.t('button.edit')}
              </Dropdown.Item>
              <Dropdown.Item onClick={onDelete}>
                {i18n.t('button.delete')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
};

OldRuleItem.defaultProps = {
  onEdit: undefined,
  onDelete: undefined,
};

export default OldRuleItem;
