import { useEffect, useState } from 'react';
import { loadHeatMap } from '../../apis/api-request';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { TimePeriod } from '../../typescript/datetime';
import { isDefined } from '../../utils/typeUtils';

export const useCameraHeatmap = (
  cameraId: number,
  timePeriod: TimePeriod,
  scenarioId?: number,
) => {
  const { userCookie } = useAuthContext();
  const { fetchCameraImageUrl } = useCameraContext();
  const [heatmapUrls, setHeatmapUrls] = useState<Record<string, string>>({});

  const heatmapKey = `${cameraId}-${scenarioId}-${timePeriod.from}-${timePeriod.until}`;

  useEffect(() => {
    const fetchHeatmap = async () => {
      if (!isDefined(scenarioId)) {
        return;
      }

      const cameraImageUrl = await fetchCameraImageUrl(cameraId);

      if (cameraImageUrl) {
        const param = {
          image_url: cameraImageUrl,
          customer_scenario_label_id: scenarioId,
          camera_id: cameraId,
          timestamp: timePeriod,
        };

        const heatmapResponse = await loadHeatMap(userCookie.userToken, param);
        if (heatmapResponse.success) {
          const heatmapUrl = URL.createObjectURL(heatmapResponse.data);
          setHeatmapUrls((prev) => ({
            ...prev,
            [heatmapKey]: heatmapUrl,
          }));
        }
      }
    };

    if (!heatmapUrls[heatmapKey]) {
      fetchHeatmap();
    }
  }, [
    cameraId,
    scenarioId,
    userCookie.userToken,
    timePeriod,
    heatmapUrls,
    heatmapKey,
    fetchCameraImageUrl,
  ]);

  return {
    heatmapUrl: heatmapUrls[heatmapKey],
  };
};
