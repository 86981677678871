import Konva from 'konva';
import moment from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthContext } from './AuthProvider';
import {
  AddCameraPayload,
  addCameras,
  CameraInitializePayload,
  initializeCamera,
  removeCameras,
  updateCameras,
  urlTempImage,
} from '../apis/api-request';
import { Camera, useGetCamerasQuery } from '../hooks/graphql/camera';
import {
  CameraProps,
  CameraUpdatePayload,
  transformCameraToUpdatePayload,
} from '../typescript/camera/camera';
import { TimePeriod } from '../typescript/datetime';
import { Scenario } from '../typescript/observation/scenario';
import i18n from '../utils/i18n';
import { isDefined, Nullable } from '../utils/typeUtils';

type HeatmapExportData = {
  imageElement: Nullable<HTMLImageElement>;
  scenario?: Scenario;
  timePeriod: TimePeriod;
};

type CameraContextType = {
  camera?: CameraUpdatePayload;
  gqlCamera?: Camera;
  cameraList: Array<CameraProps>;
  gqlCameras: Camera[];
  isCameraListLoading: boolean;

  setActiveCameraId: (cameraId: number) => void;

  addCamera(payload: AddCameraPayload): Promise<boolean>;
  removeCamera: (cameraId: number) => Promise<boolean>;
  updateCamera: (updatedCamera: CameraUpdatePayload) => Promise<boolean>;
  resetCamera: (payload: CameraInitializePayload) => Promise<boolean>;

  setHeatmapExportData: React.Dispatch<
    React.SetStateAction<HeatmapExportData | undefined>
  >;
  setDangerZoneExportLayer: React.Dispatch<
    React.SetStateAction<Konva.Layer | null>
  >;
  exportCameraImage: () => void;
  fetchCameraImageUrl: (cameraId: number) => Promise<string | null>;
};

export const CameraContext = createContext<CameraContextType | undefined>(
  undefined,
);

export const useCameraContext = () => {
  const context = useContext(CameraContext);
  if (!context) {
    throw new Error(
      'useCameraUpdateContext must be used within a CameraUpdateProvider',
    );
  }

  return context;
};

export function CameraProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { userCookie, onTokenSave, user } = useAuthContext();
  const [heatmapExportData, setHeatmapExportData] =
    useState<HeatmapExportData>();
  const [dangerZoneExportLayer, setDangerZoneExportLayer] =
    useState<Konva.Layer | null>(null);

  const [cameraImageUrls, setCameraImageUrls] = useState<
    Record<string, string | null>
  >({});
  const [activeCameraId, setActiveCameraId] = useState<number | null>(null);
  const [activeCameraImageUrl, setActiveCameraImageUrl] = useState<
    string | null
  >(null);

  const fetchCameraImageUrl = useCallback(
    async (cameraId: number) => {
      const cameraImageKey = `${cameraId}`;
      if (!isDefined(cameraImageUrls[cameraImageKey])) {
        const imageResponse = await urlTempImage(userCookie.userToken, {
          camera_id: cameraId,
        });

        if (imageResponse?.status === 401) {
          onTokenSave('');
        }

        const imageUrl: string | null = imageResponse?.data.message || null;

        setCameraImageUrls((prev) => ({
          ...prev,
          [cameraImageKey]: imageUrl,
        }));

        return imageUrl;
      }

      return cameraImageUrls[cameraImageKey];
    },
    [userCookie.userToken, cameraImageUrls, onTokenSave],
  );

  useEffect(() => {
    const fetchCameraImage = async () => {
      if (activeCameraId) {
        const imageUrl = await fetchCameraImageUrl(activeCameraId);
        setActiveCameraImageUrl(imageUrl);
      }
    };

    fetchCameraImage();
  }, [activeCameraId, fetchCameraImageUrl]);

  const {
    data: camerasData,
    refetch: refetchCameras,
    loading: isCameraListLoading,
  } = useGetCamerasQuery();

  const gqlCameras = useMemo(() => camerasData?.cameras || [], [camerasData]);
  const gqlCamera = useMemo(
    () => gqlCameras.find((item) => item.id === activeCameraId),
    [activeCameraId, gqlCameras],
  );

  const cameraList = useMemo(
    () => gqlCameras.map(transformCameraToUpdatePayload),
    [gqlCameras],
  );
  const camera = useMemo(
    () =>
      gqlCamera
        ? {
            ...transformCameraToUpdatePayload(gqlCamera),
            imageUrl: activeCameraImageUrl || gqlCamera.image_url,
          }
        : undefined,
    [gqlCamera, activeCameraImageUrl],
  );

  useEffect(() => {
    refetchCameras();
  }, [user, refetchCameras]);

  const addCamera = useCallback(
    async (param: AddCameraPayload) => {
      const addNewCamera = await addCameras(userCookie.userToken, param);
      if (addNewCamera.status === 200) {
        toast.success(addNewCamera.data.message);
        refetchCameras();
        return true;
      }
      if (addNewCamera.status === 401) {
        onTokenSave(null);
      }
      toast.error(addNewCamera.data.message);
      return false;
    },
    [onTokenSave, refetchCameras, userCookie.userToken],
  );

  const removeCamera = useCallback(
    async (cameraId: number) => {
      const deleteCamera = await removeCameras(userCookie.userToken, {
        id: cameraId,
      });

      if (deleteCamera.status === 200) {
        toast.success(deleteCamera.data.message);
        refetchCameras();
        navigate('/cameras');
        return true;
      }
      if (deleteCamera.status === 401) {
        onTokenSave('');
      }
      toast.error(deleteCamera.data.message);
      return false;
    },
    [onTokenSave, refetchCameras, userCookie.userToken, navigate],
  );

  const updateCamera = useCallback(
    async (updatedCamera: CameraUpdatePayload) => {
      const update = await updateCameras(userCookie.userToken, updatedCamera);

      switch (update.status) {
        case 200:
          toast.success(update.data.message);
          refetchCameras();
          break;

        case 401:
          toast.error(i18n.t('toast.error.token_expired'));
          onTokenSave(null);
          break;

        case 504:
          toast.error(i18n.t('toast.error.cannot_update'));
          break;
        default:
          toast.error(update.data.message);
          break;
      }
      return false;
    },
    [onTokenSave, refetchCameras, userCookie.userToken],
  );

  const resetCamera = useCallback(
    async (payload: CameraInitializePayload) => {
      const updateCameraImage = await initializeCamera(
        userCookie.userToken,
        payload,
      );

      if (updateCameraImage.status === 200) {
        toast.success(i18n.t('toast.success.reset_camera'));
        return true;
      }
      if (updateCameraImage.status === 401) {
        onTokenSave('');
      }

      toast.error(updateCameraImage.data.message);
      return false;
    },
    [onTokenSave, userCookie.userToken],
  );

  const exportCameraImage = useCallback(() => {
    if (heatmapExportData && gqlCamera) {
      const { imageElement, scenario, timePeriod } = heatmapExportData || {};
      const dateRange = `${moment.unix(timePeriod.from).format('DD MMM, YYYY, HH:mm')} ~ ${moment.unix(timePeriod.until).format('DD MMM, YYYY, HH:mm')}`;

      if (imageElement) {
        const stageWidth = imageElement.width;
        const stageHeight = imageElement.height;
        const exportLayer = new Konva.Layer();

        const padding = 10;
        const textHeight = 20;
        const containerHeight = textHeight + 4 * padding;
        const newCanvasHeight = stageHeight + containerHeight;

        const image = new Konva.Image({
          image: imageElement,
          width: stageWidth,
          height: stageHeight,
          fill: 'black',
        });
        const background = new Konva.Rect({
          x: 0,
          y: newCanvasHeight - 2 * textHeight - 2 * padding,
          width: stageWidth,
          height: containerHeight,
          fill: 'white',
        });

        const cameraText = new Konva.Text({
          text: `${i18n.t('heatmap_image.camera_name')}: ${gqlCamera.name},`,
          x: padding,
          y: newCanvasHeight - 2 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
        });
        const scenarioText = new Konva.Text({
          text: `${i18n.t('heatmap_image.scenario')}: ${scenario ? i18n.t(scenario.title) : ''}`,
          x: cameraText.width() + 8 + padding,
          y: newCanvasHeight - 2 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
        });
        const dateText = new Konva.Text({
          text: `${i18n.t('heatmap_image.date_range')}: ${dateRange}`,
          x: padding,
          y: newCanvasHeight - 1 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
          align: 'right',
        });

        exportLayer.add(image);
        exportLayer.add(background);
        exportLayer.add(cameraText);
        exportLayer.add(scenarioText);
        exportLayer.add(dateText);

        if (dangerZoneExportLayer) {
          dangerZoneExportLayer.children?.forEach((child) => {
            exportLayer.add(child.clone());
          });
        }

        exportLayer.draw();
        const uri = exportLayer.toDataURL({
          mimeType: 'image/png',
          quality: 1.0,
        });
        const link = document.createElement('a');
        link.download = 'camera-image';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        image.destroy();
        background.destroy();
        cameraText.destroy();
        scenarioText.destroy();
        dateText.destroy();
      } else {
        console.warn('Konva stage not yet available');
      }
    }
  }, [gqlCamera, heatmapExportData, dangerZoneExportLayer]);

  const context = useMemo(
    () =>
      ({
        camera,
        gqlCamera,
        cameraList,
        gqlCameras,
        isCameraListLoading,
        setHeatmapExportData,
        setActiveCameraId,
        fetchCameraImageUrl,
        addCamera,
        removeCamera,
        updateCamera,
        resetCamera,
        exportCameraImage,
        setDangerZoneExportLayer,
      }) satisfies CameraContextType,
    [
      camera,
      gqlCamera,
      cameraList,
      gqlCameras,
      isCameraListLoading,
      addCamera,
      removeCamera,
      updateCamera,
      resetCamera,
      setActiveCameraId,
      exportCameraImage,
      setDangerZoneExportLayer,
      fetchCameraImageUrl,
    ],
  );

  return (
    <CameraContext.Provider value={context}>{children}</CameraContext.Provider>
  );
}
