import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Image } from 'react-bootstrap';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import { useCameraHeatmap } from '../../hooks/api/camera';
import { Camera } from '../../hooks/graphql/camera';
import { TimePeriod } from '../../typescript/datetime';
import { Scenario } from '../../typescript/observation/scenario';
import { isEmptyObject } from '../../utils/typeUtils';
import { DangerZoneOverlay } from '../cameras/settings/danger-zone/DangerZoneOverlay';

type Props = {
  camera: Camera;
  scenario?: Scenario;
  showDangerZones: boolean;
};

export function HeatMapImageWithDangerZone({
  camera,
  scenario,
  showDangerZones = false,
}: Props) {
  const { timeRange } = useTimeRangeContext();
  const { setHeatmapExportData } = useCameraContext();

  const [isImageLoading, setIsImageLoading] = useState(true);

  const imageRef = useRef<HTMLImageElement>(null);

  const scenarioId = scenario?.id;
  const timePeriod = useMemo<TimePeriod>(
    () => ({
      from: timeRange.value,
      until: timeRange.end,
    }),
    [timeRange.value, timeRange.end],
  );

  const { heatmapUrl } = useCameraHeatmap(camera.id, timePeriod, scenarioId);

  const scenarioPerimeter = useMemo(() => {
    const cameraScenarios = camera.cameras_scenarios.find(
      (detection) => detection.customer_scenario_label.id === scenarioId,
    );

    const perimeter = isEmptyObject(cameraScenarios?.perimeter)
      ? undefined
      : cameraScenarios?.perimeter;

    return perimeter;
  }, [camera.cameras_scenarios, scenarioId]);

  useEffect(() => {
    setHeatmapExportData({
      imageElement: imageRef.current,
      scenario,
      timePeriod,
    });
  }, [setHeatmapExportData, timePeriod, scenario, imageRef]);

  const [minHeight, setMinHeight] = useState(0);
  const imageHeight = imageRef.current?.height || 0;
  const updateCanvasSize = useCallback(() => {
    if (heatmapUrl) {
      setMinHeight(imageHeight);
    }
  }, [heatmapUrl, imageHeight]);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [updateCanvasSize]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Image
        onLoad={() => setIsImageLoading(false)}
        width="100%"
        src={heatmapUrl}
        ref={imageRef}
        style={{ minHeight }}
      />

      {!isImageLoading && heatmapUrl && scenario && showDangerZones && (
        <DangerZoneOverlay
          selectedScenario={scenario}
          scenarioPerimeter={scenarioPerimeter}
          imageElement={imageRef.current}
        />
      )}
    </div>
  );
}
