import React, { PropsWithChildren } from 'react';
import { spacing } from '../../../scss/spacing';

type Props = PropsWithChildren<{
  title: string;
}>;

export function TitleWithIcon({ title, children }: Props) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: spacing(1) }}>
      {children}
      <p style={{ margin: 0 }}>{title}</p>
    </div>
  );
}
