import 'moment/locale/es';
import 'moment/locale/en-gb';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { merge } from 'ts-deepmerge';
import { Cookie, CookieSetOptions } from 'universal-cookie';
import { fileStatus } from '../apis/api-csv';
import { userLogin } from '../apis/api-request';
import { setSiteId } from '../apis/tokens';
import { useGetAllUsersQuery, useGetUserQuery } from '../hooks/graphql/user';
import { ClientUser } from '../typescript/observation/assignee';
import {
  Scenario,
  SUPPORTED_SCENARIOS,
} from '../typescript/observation/scenario';
import {
  CustomerScenario,
  DEFAULT_FEATURE_FLAGS,
  FeatureFlags,
  UserProp,
} from '../typescript/user/user';
import Mixpanel from '../utils/Mixpanel';
import Posthog from '../utils/Posthog';
import { isNotNull } from '../utils/typeUtils';

type AuthContextType = {
  user: UserProp | undefined;
  isUserLoading: boolean;
  scenariosList: Scenario[];
  clientUsersList: Array<ClientUser>;
  userCookie: {
    userToken?: any;
    language?: any;
    siteId?: number;
  };
  route: {
    to: string;
    from: string;
  };
  featureFlags: FeatureFlags;
  overrideFeatureFlags: (overrides: Partial<FeatureFlags>) => void;
  isCSVStatus: string | null;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  saveUser: (updateUser: UserProp | undefined) => void;
  refetchUser: ReturnType<typeof useGetUserQuery>['refetch'];
  setIsCSVStatus: (value: React.SetStateAction<string | null>) => void;
  setUserCookie: (
    name: 'userToken' | 'language' | 'siteId',
    value: Cookie,
    options?: CookieSetOptions,
  ) => void;
  onTokenSave: (token: string | null) => void;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [searchParams] = useSearchParams();
  const [userCookie, setUserCookie, removeUserCookie] = useCookies([
    'userToken',
    'language',
    'siteId',
  ]);
  const siteId = searchParams.get('siteId')
    ? Number(searchParams.get('siteId'))
    : undefined;
  const [user, setUser] = useState<UserProp | undefined>();
  const [clientUsersList, setClientUsersList] = useState<Array<ClientUser>>([]);
  const [scenariosList, setScenariosList] = useState<Scenario[]>([]);
  const [isCSVStatus, setIsCSVStatus] = useState<string | null>(null);
  const [featureFlagOverrides, setFeatureFlagOverrides] = useState<
    Partial<FeatureFlags>
  >({});

  const {
    loading: isUserLoading,
    error: userError,
    data: userData,
    refetch: refetchUser,
  } = useGetUserQuery();

  const {
    data: allUsersData,
    error: allUsersError,
    refetch: refetchAllUsers,
  } = useGetAllUsersQuery();

  const location = useLocation();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  useEffect(() => {
    setRoute((prev) => ({
      to: `${location.pathname}${location.search}`,
      from: prev.to,
    }));
  }, [location]);

  const onTokenSave = useCallback(
    (token: string | null) => {
      if (token) {
        setUserCookie('userToken', token, { path: '/' });
      } else {
        removeUserCookie('siteId', { path: '/' });
        removeUserCookie('userToken', { path: '/' });
      }
    },
    [setUserCookie, removeUserCookie],
  );

  const loadScenarios = (customerScenarios: CustomerScenario[]) => {
    const supportedScenarios = customerScenarios
      .map((customerScenario) => {
        const scenario = SUPPORTED_SCENARIOS.find(
          (firstItem) => firstItem.value === customerScenario.value,
        );
        return scenario
          ? ({
              ...scenario,
              ...customerScenario,
              title: customerScenario.custom_value
                ? `scenarios.${customerScenario.custom_value}.title`
                : scenario.title,
              description: customerScenario.custom_value
                ? `scenarios.${customerScenario.custom_value}.description`
                : scenario.description,
            } satisfies Scenario as Scenario)
          : null;
      })
      .filter(isNotNull);

    setScenariosList(supportedScenarios);
  };

  useEffect(() => {
    if (
      userError?.message === 'Authentication hook unauthorized this request'
    ) {
      onTokenSave('');
      refetchUser();
    }
  }, [userError, refetchUser, onTokenSave]);

  useEffect(() => {
    if (userData?.user?.at(0)) {
      const currentUser = userData.user[0];
      Posthog.identify(currentUser.trackingId, {
        $userId: currentUser.trackingId,
        $customerId: currentUser.customer.trackingId,
        $isAdmin: currentUser.isAdmin,
        $title: currentUser.title,
        $phoneNoVerified: currentUser.phoneNoVerified,
        $excludeFromTracking: currentUser.exclude_from_tracking || false,
      });

      Mixpanel.identify(currentUser.trackingId);
      Mixpanel.people.set({
        $userId: currentUser.trackingId,
        $customerId: currentUser.customer.trackingId,
        $isAdmin: currentUser.isAdmin,
        $title: currentUser.title,
        $phoneNoVerified: currentUser.phoneNoVerified,
        $excludeFromTracking: currentUser.exclude_from_tracking || false,
      });
      loadScenarios(currentUser.customer.customer_scenario_labels);
      setUser(currentUser);
      refetchAllUsers();
    }
  }, [userData, refetchAllUsers]);

  useEffect(() => {
    if (allUsersError?.message === 'Invalid response from authorization hook') {
      refetchAllUsers();
    }
    if (allUsersData) {
      const usersData = allUsersData.users;
      const usersList: Array<ClientUser> =
        user?.customerId === 7
          ? usersData
          : usersData.filter(
              (filterUser: ClientUser) =>
                !filterUser.email.includes('@buddywise.co'),
            );
      const updatedUsersList: Array<ClientUser> =
        usersList.length !== 0
          ? usersList
          : [
              {
                email: 'no assignee',
                title: null,
                phoneNo: null,
                username: null,
                isAdmin: null,
                id: null,
                customerId: null,
              },
            ];
      setClientUsersList(updatedUsersList);
    }
  }, [allUsersData, user, allUsersError, refetchAllUsers]);

  useEffect(() => {
    if (userData?.user?.at(0)) {
      const currentUser = userData.user[0];
      if (siteId) {
        setSiteId(String(siteId));
      } else if (userCookie.siteId) {
        setSiteId(userCookie.siteId);
      } else {
        const smallestId = Math.min(
          ...currentUser.customer.sites.map((obj) => obj.id),
        );
        setSiteId(String(smallestId));
      }
    }
  }, [userData, siteId, userCookie.siteId]);

  useEffect(() => {
    const fetchData = async () => {
      const makeAPICall = async () => {
        try {
          const response = await fileStatus(userCookie.userToken);
          const { files } = response.data;
          if (response.status === 401) {
            onTokenSave(null);
            setIsCSVStatus(null);
          } else if (
            Object.keys(files).length === 0 &&
            files.constructor === Object
          ) {
            setIsCSVStatus(null);
          } else if (files.CSV_EXPORT === 'pending') {
            setIsCSVStatus(files.CSV_EXPORT);
            setTimeout(makeAPICall, 2000);
          } else if (files.CSV_EXPORT && files.CSV_EXPORT === 'done') {
            setIsCSVStatus(files.CSV_EXPORT);
          } else {
            setTimeout(makeAPICall, 2000);
          }
        } catch (err) {
          setIsCSVStatus(null);
        }
      };

      makeAPICall();
    };

    if (user) {
      fetchData();
    }
  }, [isCSVStatus, user, userCookie.userToken, onTokenSave]);

  const featureFlags = merge(
    DEFAULT_FEATURE_FLAGS,
    user?.customer.feature_flags || {},
  );

  // TODO: remove hacky way to override feature flags
  featureFlags.cameras.show_details_page =
    featureFlagOverrides.cameras?.show_details_page ??
    featureFlags.cameras.show_details_page;

  featureFlags.dashboard.show_page =
    featureFlagOverrides.dashboard?.show_page ??
    featureFlags.dashboard.show_page;

  const overrideFeatureFlags = useCallback(
    (overrides: Partial<FeatureFlags>) => {
      setFeatureFlagOverrides((previous) => merge(previous, overrides));
    },
    [],
  );

  const context = useMemo(
    () => ({
      user,
      isUserLoading,
      userCookie,
      route,
      featureFlags,
      overrideFeatureFlags,
      isCSVStatus,
      scenariosList,
      clientUsersList,
      setIsCSVStatus,
      login: async (email: string, password: string) => {
        const loginUser = await userLogin({ email, password });
        if (loginUser.status === 200) {
          onTokenSave(loginUser.data.token);
          toast.success(loginUser.data.message);
          refetchUser();
          return true;
        }
        return false;
      },
      logout: async () => {
        Posthog.reset();
        Mixpanel.reset();
        setUser(undefined);
        onTokenSave(null);
        window.location.reload();
      },
      saveUser: (updateUser: UserProp | undefined) => {
        setUser(updateUser);
      },
      refetchUser,
      setUserCookie,
      onTokenSave,
    }),
    [
      onTokenSave,
      refetchUser,
      route,
      user,
      userCookie,
      clientUsersList,
      isUserLoading,
      isCSVStatus,
      setUserCookie,
      scenariosList,
      featureFlags,
      overrideFeatureFlags,
    ],
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
