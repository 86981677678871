/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { CameraProps } from '../../typescript/camera/camera';
import { ScenarioProps } from '../../typescript/observation/scenario';
import i18n from '../../utils/i18n';
import CustomDropdownWithCheckbox, {
  DropdownProps,
} from '../elements/CustomDropdownWithCheckbox';
import ScenarioDropdownWithCheckbox from '../elements/ScenarioDropdownWithCheckbox';

interface Props {
  className?: string | undefined;
  cameraList: Array<CameraProps>;
  selectedCameraList: Array<CameraProps>;
  selectedScenario: ScenarioProps | null;
  onScenario: (scenario: ScenarioProps) => void;
  onClickSelectedCameraList: (selectedCameras: Array<DropdownProps>) => void;
}

const Triggers: FC<Props> = function Triggers({
  className,
  cameraList,
  selectedCameraList,
  selectedScenario,
  onScenario,
  onClickSelectedCameraList,
}) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();
  const [selectAllCameras, setSelectAllCameras] = useState<boolean>(false);
  const [allCameraList, setAllCameraList] = useState<Array<DropdownProps>>([]);

  useEffect(() => {
    if (cameraList) {
      const cameras = cameraList.map((item) => ({
        title: item.name,
        value: item.sql_id,
        isChecked: selectedCameraList.some((o2) => item.sql_id === o2.sql_id),
      }));
      setAllCameraList(cameras);
    }
  }, [cameraList, selectedCameraList]);

  const selectedCameraLength = allCameraList.filter(
    (item) => item.isChecked,
  ).length;

  return (
    <Col md={12} className={className}>
      <p className="mb-1 weight-600">{i18n.t('triggers.title')}</p>
      <p className="sub-color">{i18n.t('triggers.body')}</p>

      <div className="d-flex align-items-center">
        <p className="m-0 me-12">{i18n.t('triggers.scenario.title')}</p>

        <ScenarioDropdownWithCheckbox
          className="rounded-pill me-12"
          title={
            selectedScenario
              ? `${i18n.t(selectedScenario.title)}`
              : `${i18n.t('button.select_scenario')}`
          }
          dropdownData={scenariosList.map((item) => ({
            ...item,
            title: i18n.t(item.title),
          }))}
          variant={`outline-${theme}`}
          bodyVariant={theme}
          onClick={(value) => {
            onScenario(value);
          }}
        />

        <p className="m-0 me-12">{i18n.t('triggers.camera.title')}</p>

        <CustomDropdownWithCheckbox
          onSelectedAll={(value, fromChild) => {
            if (!fromChild) {
              const selectAll = allCameraList.map((item) => ({
                ...item,
                isChecked: value,
              }));

              setAllCameraList(selectAll);
              onClickSelectedCameraList(
                selectAll.filter((camera) => camera.isChecked),
              );
            }

            setSelectAllCameras(value);
          }}
          selectedAll={selectAllCameras}
          className="rounded-pill"
          title={`${
            selectedCameraLength === 0
              ? i18n.t('button.select_cameras')
              : `${selectedCameraLength} ${i18n.t('button.selected')}`
          } `}
          selectAllTitle={`${i18n.t('button.select_all')}`}
          dropdownData={allCameraList}
          hideContextData="ph-no-capture"
          variant={`outline-${theme}`}
          bodyVariant={theme}
          onClick={(value) => {
            const selectAll = allCameraList.map((item) => {
              if (item.value === value.value) {
                return {
                  title: item.title,
                  value: item.value,
                  isChecked: !item.isChecked,
                };
              }
              return item;
            });
            setAllCameraList(selectAll);
            onClickSelectedCameraList(
              selectAll.filter((camera) => camera.isChecked),
            );
          }}
        />
      </div>
    </Col>
  );
};

Triggers.defaultProps = {
  className: '',
};

export default Triggers;
