/* eslint-disable no-nested-ternary */
import React, { memo, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getAlertRule,
  oldCreateAlertRule,
  oldUpdateAlertRule,
} from '../apis/api-request';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { CustomButton } from '../components/elements/CustomButton';
import { Loader } from '../components/elements/Loader';
import CameraAction from '../components/old-notification-actions/CameraAction';
import ConditionsAction from '../components/old-notification-actions/ConditionsAction';
import IntervalAction from '../components/old-notification-actions/IntervalAction';
import NameOfAlertRuleAction from '../components/old-notification-actions/NameOfAlertRuleAction';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useCameraContext } from '../contextapi/CameraProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { CameraProps } from '../typescript/camera/camera';
import { ScenarioProps } from '../typescript/observation/scenario';
import {
  conditionList,
  RuleProps,
  StringAndNumberProps,
} from '../typescript/smart-alert/old-smart-alert';
import { UserListProps } from '../typescript/user/user';
import i18n from '../utils/i18n';

const OldCreateAlertRule = () => {
  const { userCookie, onTokenSave, clientUsersList, scenariosList, route } =
    useAuthContext();
  const { theme } = useThemeContext();

  const { cameraList } = useCameraContext();

  // Navigation
  const navigate = useNavigate();

  // CameraAction
  const [selectedCameraList, setSelectedCameraList] = useState<
    Array<CameraProps>
  >([]);

  // ConditionsAction
  const [selectCondition, setSelectCondition] = useState<number>(0);
  const [selectedScenario, setSelectedScenario] =
    useState<ScenarioProps | null>(null);

  const [selectedCondition, setSelectedCondition] =
    useState<StringAndNumberProps | null>(null);
  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);

  const [selectedAction, setSelectedAction] = useState<number | null>(null);

  // const [allUsers, setAllUsers] = useState<Array<UserListProps>>([]);
  const [selectedUserList, setSelectedUserList] = useState<
    Array<UserListProps>
  >([]);

  // IntervalAction
  const [selectedInterval, setSelectedInterval] = useState<{
    type: number;
    value: number;
  }>({ type: 1, value: 3600 });

  // NameOfAlertRuleAction
  const [alertName, setAlertName] = useState<
    string | number | string[] | undefined
  >();

  // Loader
  const [loading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  // Url Params
  const { slug } = useParams<{ slug: string }>();

  async function getAlertRuleData(
    allCamerasList: Array<CameraProps>,
    allUsersList: Array<UserListProps>,
  ) {
    if (slug) {
      const param = { id: slug };

      const getRule = await getAlertRule(userCookie.userToken, param);
      if (getRule.status === 401) {
        onTokenSave('');
      }
      const rule: RuleProps = getRule.data;
      if (rule.trigger.cameras) {
        const filterArray = allCamerasList.filter(
          (item) => rule.trigger.cameras.indexOf(`${item.sql_id}`) !== -1,
        );
        setSelectedCameraList(filterArray);
      }

      const filteredScenario = scenariosList.filter(
        (scenario) => `${scenario.id}` === rule.trigger.scenarios[0],
      );
      const filteredCondition = conditionList.filter(
        (condition) => condition.value === rule.condition.period,
      );

      if (rule.action.destination) {
        const filterArray = allUsersList.filter(
          (item) =>
            rule.action.destination.indexOf(
              rule.action.type === 1 ? item.email : item.phoneNo!,
            ) !== -1,
        );
        setSelectedUserList(filterArray);
      }

      if (rule.interval <= 3599) {
        setSelectedInterval({ type: 0, value: rule.interval });
      } else if (rule.interval <= 86399) {
        setSelectedInterval({ type: 1, value: rule.interval });
      } else {
        setSelectedInterval({ type: 2, value: rule.interval });
      }

      setSelectedScenario(filteredScenario[0] ? filteredScenario[0] : null);
      setSelectCondition(rule.interval !== 5 ? 1 : 0);
      setSelectedCondition(filteredCondition[0] ? filteredCondition[0] : null);
      setSelectedNumber(
        rule.condition.min_occurance ? rule.condition.min_occurance : null,
      );
      setSelectedAction(rule.action.type ? rule.action.type : null);
      setAlertName(rule.title ? rule.title : '=');
    }
    setLoadingData(false);
  }

  useEffect(() => {
    const subscribe = async () => {
      if (userCookie) {
        const allCamerasList: Array<CameraProps> = cameraList;
        getAlertRuleData(allCamerasList, clientUsersList);
      }
    };
    subscribe();
  }, [cameraList]);

  const onCreateAlertRule = async () => {
    switch (true) {
      case selectedCameraList.length === 0:
        toast.error(i18n.t('toast.error.please_select_cameras'));
        break;
      case !alertName:
        toast.error(i18n.t('toast.error.please_add_name'));
        break;
      case !selectedScenario:
        toast.error(i18n.t('toast.error.please_select_scenario'));
        break;
      case !selectedAction:
        toast.error(i18n.t('toast.error.please_select_action'));
        break;
      case selectedUserList.length === 0:
        toast.error(i18n.t('toast.error.please_select_users_for_email'));
        break;
      default:
        {
          const destination =
            selectedAction === 1
              ? selectedUserList.map((user) => user.email)
              : selectedUserList.map((user) => user.phoneNo);

          setApiLoading(true);
          const cameraId =
            selectedCameraList.length !== 0
              ? selectedCameraList.map((item) => item.sql_id)
              : undefined;
          const param = {
            title: alertName,
            interval: selectCondition === 0 ? 5 : selectedInterval.value,
            trigger: {
              scenarios: [selectedScenario?.id],
              cameras: cameraId,
            },
            condition: {
              period: selectedCondition?.value,
              min_occurance: selectedNumber,
            },
            action: {
              type: selectedAction,
              destination,
            },
          };

          const createRule = await oldCreateAlertRule(
            userCookie.userToken,
            param,
          );
          setApiLoading(false);
          if (createRule.status === 401) {
            onTokenSave('');
          }
          if (createRule.status === 200) {
            navigate('/smart-notifications');
          }
        }
        break;
    }
  };

  const onUpdateAlertRule = async () => {
    switch (true) {
      case selectedCameraList.length === 0:
        toast.error(i18n.t('toast.error.please_select_cameras'));
        break;
      case !alertName:
        toast.error(i18n.t('toast.error.please_add_name'));
        break;
      case !selectedScenario:
        toast.error(i18n.t('toast.error.please_select_scenario'));
        break;
      case !selectedAction:
        toast.error(i18n.t('toast.error.please_select_action'));
        break;
      case selectedUserList.length === 0:
        toast.error(i18n.t('toast.error.please_select_users_for_email'));
        break;
      default:
        {
          const destination =
            selectedAction === 1
              ? selectedUserList.map((user) => user.email)
              : selectedUserList.map((user) => user.phoneNo);
          setApiLoading(true);
          const cameraId =
            selectedCameraList.length !== 0
              ? selectedCameraList.map((item) => item.sql_id)
              : undefined;
          const param = {
            id: slug,
            title: alertName,
            interval: selectCondition === 0 ? 5 : selectedInterval.value,
            trigger: {
              scenarios: [selectedScenario?.id],
              cameras: cameraId,
            },
            condition: {
              period: selectedCondition?.value,
              min_occurance: selectedNumber,
            },
            action: {
              type: selectedAction,
              destination,
            },
          };

          const createRule = await oldUpdateAlertRule(
            userCookie.userToken,
            param,
          );
          setApiLoading(false);
          if (createRule.status === 401) {
            onTokenSave('');
          }
          if (createRule.status === 200) {
            toast.success(i18n.t('toast.success.rule_updated'));
            navigate('/smart-notifications');
          }
        }
        break;
    }
  };

  if (loadingData) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  return (
    <div className="main create-alert-rule">
      <Breadcrumb
        title={`${i18n.t('smart_notifications.title')}`}
        toUrl={`${route.to !== route.from ? route.from : '/smart-notifications'}`}
      />
      <h5 className="mb-32">
        {!slug
          ? i18n.t('add_notification_rule.title')
          : i18n.t('update_notification_rule.title')}
      </h5>
      <Row>
        <Col className="container" md={12}>
          <Row>
            <CameraAction
              className="action-item"
              cameraList={cameraList}
              selectedCameraList={selectedCameraList}
              loading={loading}
              onClickSelectedCameraList={(cameras) =>
                setSelectedCameraList(cameras)
              }
            />

            <hr className="mt-24 mb-24" />

            <ConditionsAction
              className="action-item"
              selectCondition={selectCondition}
              selectedScenario={selectedScenario}
              selectedCondition={selectedCondition}
              selectedAction={selectedAction}
              selectedNumber={selectedNumber}
              userList={clientUsersList}
              selectedUserList={selectedUserList}
              loading={loading}
              setSelectCondition={(index) => {
                if (index === 0) {
                  setSelectedInterval({ type: 0, value: 5 });
                } else if (index === 1 && selectedInterval.value === 5) {
                  setSelectedInterval({ type: 1, value: 3600 });
                }
                setSelectCondition(index);
              }}
              onClickSelectedUserList={(selectedUser) => {
                setSelectedUserList(selectedUser);
              }}
              onAction={(action) => setSelectedAction(action)}
              onCondition={(condition) => setSelectedCondition(condition)}
              onScenario={(scenario) => setSelectedScenario(scenario)}
              onNumber={(number) => setSelectedNumber(number)}
            />

            {selectCondition !== 0 && (
              <>
                <hr className="mt-24 mb-24" />
                <IntervalAction
                  className="action-item"
                  selectedInterval={selectedInterval}
                  onClick={(type, value) =>
                    setSelectedInterval({ type, value })
                  }
                />
              </>
            )}

            <hr className="mt-24 mb-24" />

            <NameOfAlertRuleAction
              className="action-item"
              alertName={alertName}
              onTextChange={(event) => setAlertName(event.target.value)}
            />

            <Col md={12} lg={7} className="action-item mt-5 d-flex">
              <CustomButton
                title={i18n.t('button.cancel')}
                variant={`outline-${theme}`}
                type="button"
                onClick={() => navigate(-1)}
              />

              {!slug ? (
                <CustomButton
                  className="button-with-loader ms-12"
                  disabled={apiLoading}
                  loading={apiLoading}
                  title={i18n.t('button.create_new_rule')}
                  variant="primary"
                  type="button"
                  onClick={() => onCreateAlertRule()}
                />
              ) : (
                <CustomButton
                  className="button-with-loader ms-12"
                  disabled={apiLoading}
                  loading={apiLoading}
                  title={i18n.t('button.update_rule')}
                  variant="primary"
                  type="button"
                  onClick={() => onUpdateAlertRule()}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const OldCreateAlertRulePage = memo(OldCreateAlertRule);
