/* eslint-disable import/no-extraneous-dependencies */
import { saveAs } from 'file-saver';
import React, { FC, useState } from 'react';
import { CustomButton } from './CustomButton';
import { BUDDYWISE_API_URL } from '../../apis/api-request';
import { siteId } from '../../apis/tokens';
import { useAuthContext } from '../../contextapi/AuthProvider';

interface Props {
  imageUrl: string;
  className?: string;
  variant?: string;
}
const ImageDownloader: FC<Props> = function ImageDownloader({
  imageUrl,
  className,
  variant,
}) {
  const { userCookie } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = () => {
    setLoading(true);
    setLoading(true);
    fetch(
      `${BUDDYWISE_API_URL}/v2/images/download-image/?url=${btoa(imageUrl)}`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${userCookie.userToken}`,
          ...(siteId && { 'X-Site-Id': `${siteId}` }),
        },
      },
    )
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, 'image.jpg');
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <CustomButton
      disabled={loading}
      loading={loading}
      title="Download"
      onClick={handleDownload}
      className={`${className} button-with-loader`}
      variant={variant}
    />
  );
};

ImageDownloader.defaultProps = {
  className: '',
  variant: '',
};

export default ImageDownloader;
