import React, { useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { Scenario } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';
import ScenarioDropdownWithCheckbox from '../../elements/ScenarioDropdownWithCheckbox';

interface Props {
  hideParentModal: () => void;
}

export function ResetCamera({ hideParentModal }: Props) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();
  const { camera, resetCamera } = useCameraContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const [selectedScenario, setSelectedScenario] = useState<Scenario>();

  const handleShow = () => {
    hideParentModal();
    setShow(!show);
  };

  const handleResetCamera = async () => {
    if (!camera) return false;

    if (!selectedScenario) {
      toast.error(i18n.t('toast.error.please_select_scenario'));
      return false;
    }

    setLoading(true);
    const success = await resetCamera({
      camera_id: camera.sql_id,
      scenario_id: selectedScenario.id,
    });
    setLoading(false);

    return success;
  };

  if (!camera) return null;

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{i18n.t('camera.reset_camera.title')}</p>
      <p className="sub-color">{i18n.t('camera.reset_camera.body')}</p>

      <CustomButton
        variant={`outline-${theme}`}
        type="button"
        className="button-with-loader"
        title={i18n.t('button.reset_camera')}
        loading={loading}
        disabled={loading}
        onClick={handleShow}
      />

      <Modal
        centered
        contentClassName={theme}
        className="custom-modal schedule-modal"
        show={show}
        onHide={() => handleShow()}
      >
        <Modal.Header>
          <Modal.Title>{i18n.t('modal.reset_camera.title')}</Modal.Title>
          <CustomButton
            icon={CancelIcon}
            className="border-0"
            type="button"
            onClick={() => handleShow()}
          />
        </Modal.Header>
        <Modal.Body>
          <p className="mb-1 weight-600">
            {i18n.t('modal.reset_camera.scenario_title')}
          </p>
          <ScenarioDropdownWithCheckbox
            className="rounded-pill me-12"
            containerClassName="h-auto"
            title={
              selectedScenario
                ? `${i18n.t(selectedScenario.title)}`
                : `${i18n.t('button.select_scenario')}`
            }
            dropdownData={scenariosList
              .filter((item) => item.value === 'road-safety-eq')
              .map((item) => ({
                ...item,
                title: i18n.t(item.title),
              }))}
            variant={`outline-${theme}`}
            bodyVariant={theme}
            onClick={(value) => {
              setSelectedScenario(value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            title={i18n.t('button.back_to_settings')}
            className="button-with-loader"
            variant={`outline-${theme}`}
            type="button"
            onClick={() => handleShow()}
          />

          <CustomButton
            title={i18n.t('button.reset')}
            className="button-with-loader"
            variant="primary"
            type="button"
            onClick={handleResetCamera}
            loading={loading}
            disabled={loading || !selectedScenario}
          />
        </Modal.Footer>
      </Modal>

      <hr />
    </Col>
  );
}
