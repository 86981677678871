/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { showNumberOfItems } from '../../typescript/camera/camera';
import { intervalList } from '../../typescript/smart-alert/smart-alert';
import i18n from '../../utils/i18n';
import CustomDropdown from '../elements/CustomDropdown';

interface Props {
  className?: string | undefined;
  selectedNumber: number | null;
  selectedInterval: { type: number; value: number };
  onIntervalClick: (type: number, value: number) => void;
  onNumber: (scenario: number) => void;
}

const ConditionsAction: FC<Props> = function ConditionsAction({
  className,
  selectedNumber,
  selectedInterval,
  onIntervalClick,
  onNumber,
}) {
  const { theme } = useThemeContext();
  const { type, value } = selectedInterval;

  const getValue = () => {
    switch (type) {
      case 0:
        return value / 60;
      case 1:
        return value / 3600;
      case 2:
        return value / 86400;
      default:
        return value / 60;
    }
  };

  const getListValue = () => {
    switch (type) {
      case 0:
        return 59;
      case 1:
        return 23;
      case 2:
        return 30;
      default:
        return 59;
    }
  };

  const selectIntervalType = getValue();

  const listValue = getListValue();

  const timeList = [...Array(listValue)].map((x, index) => ({
    title: index + 1,
    value: index + 1,
  }));
  return (
    <Col md={12} className={className}>
      <p className="mb-1 weight-600">{i18n.t('conditions.title')}</p>
      <p className="sub-color">{i18n.t('conditions.body')}</p>

      <div className="d-flex align-items-center">
        <p className="m-0 me-12">{i18n.t('conditions.container_1')}</p>
        <CustomDropdown
          className="rounded-pill me-12"
          title={
            !selectedNumber
              ? `${i18n.t('button.at_least')}`
              : selectedNumber === 1
                ? `${i18n.t('button.any')}`
                : selectedNumber
          }
          dropdownData={showNumberOfItems.map((item) => ({
            title: item === 1 ? `${i18n.t('button.any')}` : item,
            value: item,
          }))}
          variant={`outline-${theme}`}
          bodyVariant={theme}
          onClick={(selectNumber) => {
            onNumber(Number(selectNumber.value));
          }}
        />

        <p className="m-0 me-12">
          {selectedNumber === 1
            ? i18n.t('conditions.any_container_2')
            : i18n.t('conditions.container_2')}
        </p>

        {selectedNumber !== 1 && (
          <>
            <CustomDropdown
              containerClassName="interval-dropdowns"
              className="rounded-pill me-12"
              title={selectIntervalType}
              dropdownData={timeList}
              variant={`outline-${theme}`}
              bodyVariant={theme}
              onClick={(IntervalValue) => {
                onIntervalClick(
                  type,
                  Number(IntervalValue.value) *
                    intervalList[selectedInterval.type].value,
                );
              }}
            />

            <CustomDropdown
              className="rounded-pill me-12"
              title={i18n.t(intervalList[selectedInterval.type].title)}
              dropdownData={intervalList.map((item, index) => ({
                ...item,
                index,
                title: `${i18n.t(item.title)}`,
              }))}
              variant={`outline-${theme}`}
              bodyVariant={theme}
              onClick={(IntervalValue) => {
                const { index } = IntervalValue;
                onIntervalClick(index || 0, Number(IntervalValue.value));
              }}
            />
          </>
        )}
      </div>
    </Col>
  );
};

ConditionsAction.defaultProps = {
  className: '',
};

export default ConditionsAction;
