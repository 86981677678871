import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { MultiBarChartPlaceholder } from './MultiBarChartPlaceholder';
import { ScenariosLegend } from './ScenariosLegend';
import { BarTooltip } from './tools/BarTooltip';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../../../contextapi/TimeRangeProvider';
import { useNavigateToObservation } from '../../../hooks/charts';
import { ObservationGroup } from '../../../hooks/graphql/chart';
import {
  combineObservations,
  groupDataByDates,
} from '../../../typescript/grouping/grouping-observation';
import { ScenarioName } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { useTracker } from '../../../utils/Tracker';

interface Props {
  data: ObservationGroup[];
  cameraIds?: Array<number>;
  isLoading?: boolean;
}

export function MultiBarChart({ data, cameraIds, isLoading = false }: Props) {
  const { theme } = useThemeContext();
  const { observedScenarios } = useScenarioContext();
  const { timeRange } = useTimeRangeContext();
  const { trackBarClick } = useTracker();
  const { navigateToObservation } = useNavigateToObservation();

  const [hiddenScenarios, setHiddenScenarios] = useState<Set<ScenarioName>>(
    new Set(),
  );

  const totalObservations = useMemo(
    () =>
      data.reduce(
        (total, { timeseries }) =>
          total + timeseries.reduce((acc, { count }) => acc + count, 0),
        0,
      ),
    [data],
  );

  const scenariosByDate = useMemo(
    () =>
      groupDataByDates(
        combineObservations(data, 'DD MMM, YYYY'),
        observedScenarios,
        timeRange.isHourly,
      ),
    [data, observedScenarios, timeRange.isHourly],
  );

  return (
    <Col md={8} className="mb-32">
      <Card
        className={`card-${theme} border border-${theme} border-radius h-100 placeholder-glow`}
      >
        <Card.Body className="d-flex flex-column">
          <Card.Text className="text-center weight-600">
            {i18n.t('analytics_overview.daily_chart')}
          </Card.Text>
          {isLoading ? (
            <MultiBarChartPlaceholder />
          ) : (
            <ResponsiveContainer height={300} className="ph-no-capture">
              <BarChart data={scenariosByDate}>
                <YAxis
                  tickMargin={10}
                  strokeOpacity={0.3}
                  stroke={theme === 'light' ? '#1f2321' : '#ffffff'}
                />
                <XAxis
                  dataKey="date"
                  tickMargin={10}
                  tickFormatter={(tick: string) =>
                    timeRange.isHourly
                      ? moment(tick).format('HH:mm')
                      : tick.slice(0, -6)
                  }
                  stroke={theme === 'light' ? '#1f2321' : '#ffffff'}
                  strokeOpacity={0.3}
                />
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke={theme === 'light' ? '#cfd3cf' : '#6f7676'}
                />
                {observedScenarios.map((item) => (
                  <Bar
                    key={item.value}
                    dataKey={item.value}
                    stackId="stacked"
                    hide={hiddenScenarios.has(item.value)}
                    fill={item.color}
                    onClick={(_, index) => {
                      const startOfDay = moment(
                        scenariosByDate[index].date,
                      ).unix();
                      let endOfDay: number;
                      if (scenariosByDate.length - 1 !== index) {
                        endOfDay = moment(
                          scenariosByDate[index + 1].date,
                        ).unix();
                      } else {
                        endOfDay = moment(scenariosByDate[index].date)
                          .endOf('day')
                          .unix();
                      }
                      const scenarioIds = observedScenarios
                        .filter((scenario) => !scenario.active)
                        .map((scenario) => scenario.id || 0);

                      trackBarClick('Stacked Bar Chart Column Click', {
                        timeRange: {
                          title: timeRange.text,
                          value: startOfDay,
                          end: endOfDay,
                        },
                        scenarios: observedScenarios
                          .filter((scenario) => !scenario.active)
                          .map((scenario) => scenario.value),
                      });

                      navigateToObservation({
                        timeRange: {
                          value: startOfDay,
                          end: endOfDay,
                        },
                        scenarioIds,
                        cameraIds,
                      });
                    }}
                    className="add-cursor"
                  />
                ))}
                <Legend
                  content={
                    <ScenariosLegend
                      onScenarioClick={(scenario) => {
                        const newScenarios = new Set(hiddenScenarios);
                        if (hiddenScenarios.has(scenario.value)) {
                          newScenarios.delete(scenario.value);
                        } else {
                          newScenarios.add(scenario.value);
                        }

                        setHiddenScenarios(newScenarios);
                      }}
                      hiddenScenarios={hiddenScenarios}
                    />
                  }
                />
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  content={<BarTooltip totalObservations={totalObservations} />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}
