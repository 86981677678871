import React, { FC, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { Scenario } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import ScenarioDropdownWithCheckbox from '../../elements/ScenarioDropdownWithCheckbox';

interface Props {
  selectedScenarioList: Array<Scenario>;
  onClick: (scenario: Array<Scenario>) => void;
}

const ScenarioFilter: FC<Props> = function ScenarioFilter({
  selectedScenarioList,
  onClick,
}) {
  const { theme } = useThemeContext();
  const [selectAllScenario, setSelectAllScenario] = useState<boolean>(
    selectedScenarioList.length ===
      selectedScenarioList.filter((item) => item.active).length,
  );

  const ALL_SCENARIO_MODELS = selectedScenarioList.map((item) => ({
    ...item,
    title: i18n.t(item.title),
  }));

  return (
    <Col md={12}>
      <hr />
      <p className="mb-1 weight-600">{i18n.t('filter.scenarios.title')}</p>
      <p className="sub-color">{i18n.t('filter.scenarios.body')}</p>

      <ScenarioDropdownWithCheckbox
        isCheckBox
        onSelectedAll={(value, fromChild) => {
          setSelectAllScenario(value);
          if (!fromChild) {
            const selectAll = selectedScenarioList.map((item) => ({
              ...item,
              title: i18n.t(item.title),
              active: value,
            }));
            onClick(selectAll);
          }
        }}
        selectedAll={selectAllScenario}
        className="rounded-pill"
        title={`${i18n.t('button.show_all_scenarios')}`}
        selectAllTitle={`${i18n.t('button.select_all')}`}
        dropdownData={ALL_SCENARIO_MODELS}
        variant={`outline-${theme}`}
        bodyVariant={theme}
        onClick={(value) => {
          const selectAll = ALL_SCENARIO_MODELS.map((item) => {
            if (item.value === value.value) {
              return {
                ...item,
                title: i18n.t(item.title),
                active: !item.active,
              };
            }
            return item;
          });
          onClick(selectAll);
        }}
      />
    </Col>
  );
};

export default ScenarioFilter;
