import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { DonutChart } from './charts/DonutChart';
import { MultiBarChart } from './charts/MultiBarChart';
import { EmptyChart } from './EmptyChart';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import {
  ObservationGroup,
  useGetChartDataQuery,
} from '../../hooks/graphql/chart';
import { updatedDate } from '../../typescript/grouping/grouping-observation';

type Props = {
  cameraIds?: Array<number>;
  onObservationGroupsLoaded?: (observationGroups: ObservationGroup[]) => void; // TODO: better name?
};

export function ObservationCharts({
  cameraIds,
  onObservationGroupsLoaded,
}: Props) {
  const { timeRange } = useTimeRangeContext();
  const { updateObservedScenarios: updateObservationGroups } =
    useScenarioContext();

  const variables = {
    from: timeRange.value,
    until: timeRange.end,
    camera_ids: cameraIds,
  };

  const { loading, data, refetch } = useGetChartDataQuery(variables);

  useEffect(() => {
    refetch();
  }, [timeRange, refetch]);

  useEffect(() => {
    if (data?.chartData && onObservationGroupsLoaded) {
      onObservationGroupsLoaded(data.chartData);
    }
    if (data?.chartData) {
      updateObservationGroups(data.chartData);
    }
  }, [data?.chartData, onObservationGroupsLoaded, updateObservationGroups]);

  const observationGroups = useMemo(
    () =>
      (data?.chartData || []).map((group) => ({
        ...group,
        timeseries: updatedDate(
          group.timeseries.map((item) => ({
            count: item.count,
            date: moment(item.range).format('DD MMM'),
            range: item.range,
          })),
          timeRange.text,
          timeRange.value,
          timeRange.end,
          timeRange.isHourly,
        ),
      })),
    [timeRange, data?.chartData],
  );

  return !loading && observationGroups?.length === 0 ? (
    <EmptyChart />
  ) : (
    <>
      <DonutChart
        data={observationGroups}
        cameraIds={cameraIds}
        isLoading={loading}
      />
      <MultiBarChart
        data={observationGroups}
        cameraIds={cameraIds}
        isLoading={loading}
      />
    </>
  );
}
