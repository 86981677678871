import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { Scenario } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';

type Props = {
  scenario: Scenario;
  iconType?: 'add' | 'remove';
  onClick?: (scenario: Scenario) => void;
};

export function ScenarioLabel({ scenario, iconType, onClick }: Props) {
  const { theme } = useThemeContext();
  const { description, color, colorLight, value, title } = scenario;

  return (
    <OverlayTrigger
      key={value}
      placement="bottom"
      overlay={<Tooltip id="button-tooltip-2">{i18n.t(description)}</Tooltip>}
    >
      <li
        className="scenario-list me-2 mb-12"
        key={value}
        style={{
          backgroundColor: theme === 'light' ? colorLight : color,
          color: theme === 'light' ? color : '#fff',
          cursor: 'help',
        }}
      >
        {i18n.t(title)}
        {iconType && (
          <span style={{ cursor: 'pointer' }}>
            {iconType === 'remove' ? (
              <svg
                className="ms-2"
                onClick={() => onClick?.(scenario)}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7M1 1L7 7"
                  stroke={theme === 'light' ? color : '#fff'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                className="ms-2"
                onClick={() => onClick?.(scenario)}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.24264 0.757812V5.00045M5.24264 5.00045V9.24309M5.24264 5.00045H1M5.24264 5.00045H9.48528"
                  stroke={theme === 'light' ? color : '#fff'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
        )}
      </li>
    </OverlayTrigger>
  );
}
