import React, { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { ObservationList } from '../components/observation/ObservationList';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import i18n from '../utils/i18n';

export const ObservationListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthContext();
  const { theme } = useThemeContext();

  const defaultTab = searchParams.get('type')
    ? Number(searchParams.get('type'))
    : 0;
  const [activeTab, setActiveTab] = useState<number>(defaultTab);

  return (
    <>
      <Breadcrumb title={`${i18n.t('dashboard.title')}`} toUrl="/" />
      <h5 className="mb-32">{i18n.t('landing.title')}</h5>
      <Row className="landing">
        <Col lg={12} className="d-flex flex-column">
          <Tabs
            defaultActiveKey={activeTab}
            className={`mb-32 nav-tabs-${theme}`}
            onSelect={(tabIndex) => {
              if (tabIndex === null) return;

              setActiveTab(Number(tabIndex));
              setSearchParams((searchParams) => {
                searchParams.set('type', tabIndex);
                return searchParams;
              });
            }}
          >
            <Tab
              tabClassName="me-12"
              eventKey={0}
              title={i18n.t('landing.tab.all')}
            >
              {activeTab === 0 && <ObservationList />}
            </Tab>
            <Tab
              tabClassName="d-flex flex-column"
              eventKey={1}
              title={i18n.t('landing.tab.assigne_me')}
            >
              {activeTab === 1 && <ObservationList assignee={user?.email} />}
            </Tab>
            <Tab
              tabClassName="d-flex flex-column"
              eventKey={2}
              title={i18n.t('landing.tab.bookmarked')}
            >
              {activeTab === 2 && <ObservationList variant="bookmark" />}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};
