import { ApolloProvider } from '@apollo/client';
import { Profiler } from '@sentry/react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './contextapi/AuthProvider';
import { useThemeContext } from './contextapi/ThemeProvider';
import { TranslationProvider } from './contextapi/TranslationProvider';
import { client } from './graphql/graph-ql-client';
import { Router } from './routes/Router';

export function App() {
  const { theme } = useThemeContext();

  return (
    <Profiler>
      <ToastContainer
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <div className={`${theme}`}>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <AuthProvider>
              <TranslationProvider>
                <Router />
              </TranslationProvider>
            </AuthProvider>
          </ApolloProvider>
        </BrowserRouter>
      </div>
    </Profiler>
  );
}
