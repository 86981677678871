import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useAuthContext } from './AuthProvider';
import { ObservationGroup } from '../hooks/graphql/chart';
import {
  convertToScenarioName,
  Scenario,
  ScenarioName,
} from '../typescript/observation/scenario';

type ScenarioContextType = {
  userScenarios: Scenario[];
  observedScenarios: Scenario[];
  updateObservedScenarios: (observationGroups: ObservationGroup[]) => void;
  getScenarioById: (scenarioId?: number) => Scenario | undefined;
  getScenarioByName: (scenarioName?: ScenarioName) => Scenario | undefined;
  getScenariosByName: (scenarioNames: ScenarioName[]) => Scenario[];
};

export const ScenarioContext = createContext<ScenarioContextType | undefined>(
  undefined,
);

export const useScenarioContext = () => {
  const context = useContext(ScenarioContext);
  if (!context) {
    throw new Error(
      'useScenarioContext must be used within a ScenarioProvider',
    );
  }

  return context;
};

export function ScenarioProvider({ children }: PropsWithChildren) {
  const { scenariosList: userScenarios } = useAuthContext();
  const [observedScenarios, setObservedScenarios] = useState<Scenario[]>([]);

  const getScenarioById = useCallback(
    (scenarioId?: number) =>
      userScenarios.find((scenario) => scenario.id === scenarioId),
    [userScenarios],
  );

  const getScenarioByName = useCallback(
    (scenarioName?: ScenarioName) =>
      userScenarios.find((scenario) => scenario.value === scenarioName),
    [userScenarios],
  );

  const getScenariosByName = useCallback(
    (scenarioNames: ScenarioName[]) =>
      userScenarios.filter((scenario) =>
        scenarioNames.includes(scenario.value),
      ),
    [userScenarios],
  );

  const updateObservedScenarios = useCallback(
    (observationGroups: ObservationGroup[]) => {
      const scenarioNames = Array.from(
        new Set(
          observationGroups.flatMap(
            (observation) => observation.scenario_names,
          ),
        ),
      ).map(convertToScenarioName);

      setObservedScenarios(getScenariosByName(scenarioNames));
    },
    [getScenariosByName],
  );

  const context = useMemo(
    () => ({
      userScenarios,
      observedScenarios,
      updateObservedScenarios,
      getScenarioById,
      getScenarioByName,
      getScenariosByName,
    }),
    [
      userScenarios,
      observedScenarios,
      updateObservedScenarios,
      getScenarioById,
      getScenarioByName,
      getScenariosByName,
    ],
  );

  return (
    <ScenarioContext.Provider value={context}>
      {children}
    </ScenarioContext.Provider>
  );
}
