import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ObservationModalDefault } from './observation/ObservationModalDefault';
import { ObservationModalExtended } from './observation/ObservationModalExtended';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { ObservationProp } from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { CustomButton } from '../elements/CustomButton';
import { CustomSwitch } from '../elements/CustomSwitch';
import { Loader } from '../elements/Loader';
import { SectionTitle } from '../elements/SectionTitle';

interface Props {
  variant?: 'default' | 'extended';
  observation: ObservationProp;
  startingObservationUnix?: number;
  showModal: boolean;
  pageNumber?: number;
  currentIndex?: number;
  isAllAction?: boolean;
  onLoading?: boolean;
  onNextPress?: () => void;
  onBackPress?: () => void;
  onFKeyPress?: () => void;
  onAKeyPress?: () => void;
  onRKeyPress?: () => void;
  onCloseClick: () => void;
}

export function ObservationModal({
  variant = 'default',
  showModal,
  observation,
  startingObservationUnix,
  onLoading,
  isAllAction,
  pageNumber,
  currentIndex,
  onCloseClick,
  onNextPress,
  onBackPress,
  onFKeyPress,
  onAKeyPress,
  onRKeyPress,
}: Props) {
  // Context
  const { theme } = useThemeContext();
  const { user } = useAuthContext();

  // State
  const [hideLabel, setHideLabel] = useState<boolean>(true);

  useEffect(() => {
    if (variant === 'default') {
      const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'ArrowRight') {
          event.preventDefault();
          onNextPress?.();
        }
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          onBackPress?.();
        }
        if (event.shiftKey && event.key === 'F') {
          event.preventDefault();
          onFKeyPress?.();
        }
        if (event.shiftKey && event.key === 'A') {
          event.preventDefault();
          onAKeyPress?.();
        }
        if (event.shiftKey && event.key === 'R') {
          event.preventDefault();
          onRKeyPress?.();
        }
      };

      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
    return () => false;
  }, [
    currentIndex,
    onLoading,
    observation,
    variant,
    onNextPress,
    onBackPress,
    onFKeyPress,
    onAKeyPress,
    onRKeyPress,
  ]);

  useEffect(() => {
    const showLabels =
      user?.customer.feature_flags.observations.show_labels_by_default;
    const labelValue = showLabels === undefined ? true : showLabels;
    setHideLabel(labelValue);
  }, [user]);

  if (!showModal) {
    return null;
  }

  const showAnnotation =
    user?.customer.feature_flags.observations.show_annotation;
  const isAnnotation =
    showAnnotation && observation && observation.id >= Number(showAnnotation);

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal observation-image danger-zone-modal"
      show={showModal}
      onHide={() => onCloseClick()}
    >
      <Modal.Header>
        {variant === 'default' ? (
          <div className="d-flex">
            <span className={`me-12 ${!isAllAction && 'd-none'}`}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {i18n.t('show_observation_modal.hint')}
                  </Tooltip>
                }
              >
                <InfoIcon className="icon" />
              </OverlayTrigger>
            </span>
            {isAnnotation && (
              <CustomSwitch
                label={`${i18n.t('modal.observation.switch.show_labels')}`}
                checked={hideLabel}
                onChange={(e) => setHideLabel(e.currentTarget.checked)}
              />
            )}
          </div>
        ) : (
          <SectionTitle
            title={`${i18n.t('modal.observation.title')}`}
            removeMargins
          />
        )}

        <CustomButton
          icon={CancelIcon}
          className="border-0 ms-auto"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div
          className={`position-absolute bottom-50 ${!onLoading && 'd-none'} ${theme}`}
          style={{
            left: '48%',
            zIndex: 1,
            padding: 10,
            borderRadius: '50%',
          }}
        >
          <Loader main />
        </div>
        {variant === 'extended' && startingObservationUnix && (
          <ObservationModalExtended
            hideLabel={hideLabel}
            isAnnotation={isAnnotation || false}
            observation={observation}
            startingObservationUnix={startingObservationUnix}
            pageNumber={pageNumber}
            currentIndex={currentIndex}
            hideHits={isAllAction}
            setHideLabel={(value) => setHideLabel(value)}
          />
        )}

        {variant === 'default' && (
          <ObservationModalDefault
            hideLabel={hideLabel}
            isAnnotation={isAnnotation || false}
            observation={observation}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
