import React, { FC, useState } from 'react';
import {
  Col,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { testNotificationRule } from '../../apis/api-request';
import { ReactComponent as ConditionIcon } from '../../assets/icons/condition.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { numberOfIncident } from '../../typescript/camera/camera';
import { ScenarioProps } from '../../typescript/observation/scenario';
import {
  actionList,
  conditionList,
  StringAndNumberProps,
} from '../../typescript/smart-alert/old-smart-alert';
import { UserListProps } from '../../typescript/user/user';
import i18n from '../../utils/i18n';
import { CustomButton } from '../elements/CustomButton';

interface Props {
  className?: string | undefined;
  selectedScenario: ScenarioProps | null;
  selectCondition: number;
  selectedCondition: StringAndNumberProps | null;
  selectedAction: number | null;
  userList: Array<UserListProps>;
  selectedUserList: Array<UserListProps>;
  loading?: boolean;
  selectedNumber: number | null;

  setSelectCondition: (index: number) => void;
  onScenario: (scenario: ScenarioProps) => void;
  onCondition: (condition: StringAndNumberProps) => void;
  onAction: (scenario: number) => void;
  onNumber: (scenario: number) => void;
  onClickSelectedUserList: (selectedCameras: Array<UserListProps>) => void;
}

const ConditionsAction: FC<Props> = function ConditionsAction({
  className,
  selectedScenario,
  selectCondition,
  selectedCondition,
  selectedAction,
  userList,
  selectedUserList,
  loading,
  selectedNumber,
  setSelectCondition,
  onCondition,
  onNumber,
  onAction,
  onClickSelectedUserList,
  onScenario,
}) {
  const { userCookie, onTokenSave, scenariosList } = useAuthContext();
  const { theme } = useThemeContext();
  const [selectedAllUser, setSelectedAllUser] = useState<boolean>(false);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const conditionTypes = ['immediate', 'condition_based'];

  const onTestNotification = async () => {
    const destination =
      selectedAction === 1
        ? selectedUserList.map((user) => user.email)
        : selectedUserList.map((user) => user.phoneNo);
    setApiLoading(true);
    const param = {
      method: selectedAction === 1 ? 'email' : 'sms',
      addresses: destination,
    };

    const testNotification = await testNotificationRule(
      userCookie.userToken,
      param,
    );
    setApiLoading(false);
    if (testNotification.status === 401) {
      onTokenSave('');
    }
    if (testNotification.status === 200) {
      toast.success(testNotification.data.message);
    }
  };

  return (
    <Col md={12} lg={7} className={className}>
      <div className="title-div">
        <div className="circle">
          <ConditionIcon />
        </div>
        <div className="body-div">
          <p className="mb-1 weight-600">{i18n.t('select_conditions.title')}</p>
          <p className="sub-color">{i18n.t('select_conditions.body')}</p>

          {conditionTypes.map((item, index) => (
            <>
              <FormCheck
                key={`${item}`}
                label={i18n.t(`radiobox.${item}`)}
                className="weight-600"
                name="group1"
                type="radio"
                checked={index === selectCondition}
                onClick={() => setSelectCondition(index)}
              />
              <p className="sub-color">{i18n.t(`radiobox.${item}.body`)}</p>
            </>
          ))}

          <div
            className={`border border-${theme} border-radius condition-layout condition-layout-${theme}`}
          >
            <p className="m-0 weight-600">{i18n.t('trigger.title')}</p>
            <p className="sub-color">{i18n.t('trigger.body')}</p>
            <Dropdown>
              <Dropdown.Toggle
                className="rounded-pill"
                variant={`outline-${theme}`}
                id="dropdown-basic"
              >
                {!selectedScenario
                  ? `${i18n.t('button.select_scenario')}`
                  : i18n.t(selectedScenario.title)}
              </Dropdown.Toggle>

              <Dropdown.Menu variant={theme}>
                {scenariosList.map((item: ScenarioProps, index: number) => (
                  <Dropdown.Item
                    className="no-hover"
                    key={`${item.value + index}`}
                    onClick={() => {
                      onScenario(item);
                    }}
                  >
                    <span
                      className="scenario-list"
                      style={{
                        backgroundColor:
                          theme === 'light' ? item.colorLight : item.color,
                        color: theme === 'light' ? item.color : '#fff',
                      }}
                    >
                      {i18n.t(item.title)}
                    </span>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {selectCondition === 1 && (
            <div
              className={`border border-${theme} border-radius condition-layout condition-layout-${theme}`}
            >
              <p className="m-0 weight-600">
                {i18n.t('condition.title')}{' '}
                <span className="sub-color">({i18n.t('optional.title')})</span>
              </p>
              <p className="sub-color">{i18n.t('condition.body')}</p>
              <Dropdown>
                <Dropdown.Toggle
                  className="rounded-pill"
                  variant={`outline-${theme}`}
                  id="dropdown-basic"
                >
                  {selectedCondition === null
                    ? `${i18n.t('button.select_condition')}`
                    : i18n.t(selectedCondition.title)}
                </Dropdown.Toggle>

                <Dropdown.Menu variant={theme}>
                  {conditionList.map(
                    (item: StringAndNumberProps, index: number) => (
                      <Dropdown.Item
                        key={`${item.title + index}`}
                        onClick={() => onCondition(item)}
                      >
                        {i18n.t(item.title)}
                      </Dropdown.Item>
                    ),
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="mt-12">
                <Dropdown.Toggle
                  className="rounded-pill"
                  variant={`outline-${theme}`}
                  id="dropdown-basic"
                >
                  {!selectedNumber
                    ? `${i18n.t('button.at_least')}`
                    : selectedNumber}
                </Dropdown.Toggle>

                <Dropdown.Menu variant={theme}>
                  {numberOfIncident.map((item: number, index: number) => (
                    <Dropdown.Item
                      key={`${item + index}`}
                      onClick={() => {
                        onNumber(item);
                      }}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          <div
            className={`border border-${theme} border-radius condition-layout condition-layout-${theme} mb-0`}
          >
            <p className="m-0 weight-600">{i18n.t('require_action.title')}</p>
            <p className="sub-color">{i18n.t('require_action.body')}</p>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Dropdown>
                  <Dropdown.Toggle
                    className="rounded-pill"
                    variant={`outline-${theme}`}
                    id="dropdown-basic"
                  >
                    {selectedAction === null
                      ? `${i18n.t('button.select_action')}`
                      : i18n.t(actionList[selectedAction - 1])}
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant={theme}>
                    {actionList.map((item: string, index: number) => (
                      <Dropdown.Item
                        key={`${item + index}`}
                        onClick={() => {
                          onAction(index + 1);
                          onClickSelectedUserList([]);
                        }}
                      >
                        {i18n.t(item)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                {selectedAction === 1 && (
                  <Dropdown className="ms-12">
                    <Dropdown.Toggle
                      className="rounded-pill"
                      variant={`outline-${theme}`}
                      id="dropdown-basic"
                    >
                      {selectedUserList.length === 0 &&
                        i18n.t('button.select_user')}
                      {selectedUserList.length !== 0 &&
                        `${selectedUserList.length} ${selectedUserList.length === 1 ? i18n.t('button.selected_user') : i18n.t('button.selected_users')}`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant={theme}>
                      <div className="dropdown-item">
                        {userList.length !== 0 && (
                          <FormCheck
                            checked={selectedAllUser}
                            className="check-box"
                            label={i18n.t('button.select_all')}
                            disabled={userList[0].email === 'no assignee'}
                            onClick={(e: any) => {
                              if (e.target.checked) {
                                onClickSelectedUserList(userList);
                                setSelectedAllUser(!selectedAllUser);
                              } else {
                                onClickSelectedUserList([]);
                                setSelectedAllUser(!selectedAllUser);
                              }
                            }}
                          />
                        )}
                      </div>

                      {loading
                        ? `${i18n.t('loading.title')}`
                        : userList.map((item) => {
                            const isChecked: boolean = selectedUserList.some(
                              (elemet) => elemet === item,
                            );
                            return (
                              <div
                                className="dropdown-item ph-no-capture"
                                key={item.email}
                              >
                                <FormCheck
                                  checked={isChecked}
                                  className="check-box"
                                  label={item.email}
                                  onClick={(e: any) => {
                                    if (e.target.checked) {
                                      onClickSelectedUserList([
                                        ...selectedUserList,
                                        item,
                                      ]);
                                    } else {
                                      const withoutEl =
                                        selectedUserList?.filter(
                                          (elemet) => elemet !== item,
                                        );
                                      onClickSelectedUserList(withoutEl);
                                      setSelectedAllUser(false);
                                    }
                                  }}
                                  disabled={userList[0].email === 'no assignee'}
                                />
                              </div>
                            );
                          })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {selectedAction === 2 && (
                  <Dropdown className="ms-12">
                    <Dropdown.Toggle
                      className="rounded-pill"
                      variant={`outline-${theme}`}
                      id="dropdown-basic"
                    >
                      {selectedUserList.length === 0 &&
                        i18n.t('button.select_user')}
                      {selectedUserList.length !== 0 &&
                        `${selectedUserList.length} ${selectedUserList.length === 1 ? i18n.t('button.selected_user') : i18n.t('button.selected_users')}`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant={theme}>
                      <div className="dropdown-item">
                        <FormCheck
                          checked={selectedAllUser}
                          className="check-box"
                          label={i18n.t('button.select_all')}
                          disabled={userList[0].email === 'no assignee'}
                          onClick={(e: any) => {
                            if (e.target.checked) {
                              onClickSelectedUserList(userList);
                              setSelectedAllUser(!selectedAllUser);
                            } else {
                              onClickSelectedUserList([]);
                              setSelectedAllUser(!selectedAllUser);
                            }
                          }}
                        />
                      </div>

                      {loading
                        ? `${i18n.t('loading.title')}`
                        : userList
                            .filter((userFilter) => userFilter.phoneNo)
                            .map((item) => {
                              const isChecked: boolean = selectedUserList.some(
                                (element) => element === item,
                              );
                              return (
                                <OverlayTrigger
                                  key={item.email}
                                  placement="bottom"
                                  overlay={
                                    <Tooltip
                                      className={`${item.username !== null && 'd-none'}`}
                                      id="button-tooltip-2"
                                    >
                                      {i18n.t('text.user_info_incomplete')}
                                    </Tooltip>
                                  }
                                >
                                  <div className="dropdown-item ph-no-capture">
                                    <FormCheck
                                      checked={isChecked}
                                      className="check-box"
                                      label={
                                        item.username ||
                                        i18n.t('text.username_is_missing')
                                      }
                                      disabled={
                                        item.phoneNo === null ||
                                        userList[0].email === 'no assignee'
                                      }
                                      onClick={(e: any) => {
                                        if (e.target.checked) {
                                          onClickSelectedUserList([
                                            ...selectedUserList,
                                            item,
                                          ]);
                                        } else {
                                          const withoutEl =
                                            selectedUserList.filter(
                                              (element) => element !== item,
                                            );
                                          onClickSelectedUserList(withoutEl);
                                          setSelectedAllUser(false);
                                        }
                                      }}
                                    />
                                  </div>
                                </OverlayTrigger>
                              );
                            })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>

              {selectedUserList.length !== 0 && (
                <CustomButton
                  title={i18n.t('button.test_notification')}
                  variant={`outline-${theme}`}
                  className="button-with-loader"
                  type="button"
                  disabled={apiLoading}
                  loading={apiLoading}
                  onClick={onTestNotification}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

ConditionsAction.defaultProps = {
  className: '',
  loading: false,
};

export default ConditionsAction;
