/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import React from 'react';
import { TitleWithIcon } from './TitleWithIcon';
import { ReactComponent as AssigneeIcon } from '../../../assets/icons/modal/observation/assignee.svg';
import { ReactComponent as CameraIcon } from '../../../assets/icons/modal/observation/camera.svg';
import { ReactComponent as DateIcon } from '../../../assets/icons/modal/observation/date.svg';
import { ReactComponent as PriorityIcon } from '../../../assets/icons/modal/observation/priority.svg';
import { ReactComponent as ScenarioIcon } from '../../../assets/icons/modal/observation/scenario.svg';
import { ReactComponent as StatusIcon } from '../../../assets/icons/modal/observation/status.svg';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { spacing } from '../../../scss/spacing';
import {
  OBSERVATION_PRIORITIES,
  OBSERVATION_STATUSES,
  ObservationProp,
} from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';

type Props = {
  observation: ObservationProp;
};

export function ObservationDetailView({ observation }: Props) {
  const { theme } = useThemeContext();
  const { getScenarioByName } = useScenarioContext();

  const scenario = getScenarioByName(observation.customer_scenario_label.name);

  const priority = OBSERVATION_PRIORITIES.find(
    (priority) => priority.value === observation?.priority,
  );
  const status = OBSERVATION_STATUSES.find(
    (status) => status.value === observation?.status,
  );
  return (
    <div
      style={{
        borderTop: '1px solid',
        display: 'flex',
        alignItems: 'center',
        gap: spacing(3),
        padding: `${spacing(4)}  ${spacing(3)}`,
      }}
      className={`border-${theme}`}
      css={css`
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width: 768px) {
          justify-content: flex-start;
        }
      `}
    >
      <TitleWithIcon
        title={moment
          .unix(observation.system_timestamp)
          .format('DD MMM, HH:mm:ss')}
      >
        <DateIcon />
      </TitleWithIcon>
      {scenario && (
        <TitleWithIcon title={i18n.t(scenario.title)}>
          <ScenarioIcon />
        </TitleWithIcon>
      )}
      <TitleWithIcon
        title={observation.camera?.name || i18n.t('td.camera_no_longer_exists')}
      >
        <CameraIcon />
      </TitleWithIcon>
      <TitleWithIcon title={observation.responder || i18n.t('td.not_assigned')}>
        <AssigneeIcon />
      </TitleWithIcon>
      {status && (
        <TitleWithIcon title={i18n.t(status.title)}>
          <StatusIcon />
        </TitleWithIcon>
      )}
      {priority && (
        <TitleWithIcon title={i18n.t(priority.title)}>
          <PriorityIcon />
        </TitleWithIcon>
      )}
    </div>
  );
}
