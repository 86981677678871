import { useState } from 'react';
import { urlTempImage, urlTempImageV2 } from '../apis/api-request';
import { siteId } from '../apis/tokens';
import { useAuthContext } from '../contextapi/AuthProvider';
import { ObservationProp } from '../typescript/observation/observation';

export const useObservationImage = () => {
  const { userCookie, user } = useAuthContext();
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isImageFound, setIsImageFound] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  return {
    getObservationImageUrl: async (
      observation: ObservationProp,
      type: 'original' | 'thumbnail',
    ) => {
      if (userCookie && retryCount < 2) {
        const param = {
          customer_id: user?.customerId,
          site_id: Number(siteId),
          request_id: observation.requestId,
          version: type,
        };

        const oldParam = { observation_id: observation.id };
        const show_thumbnail =
          user?.customer.feature_flags.image?.show_thumbnail;
        try {
          const getImageUrl = show_thumbnail
            ? await urlTempImageV2(userCookie.userToken, param)
            : await urlTempImage(userCookie.userToken, oldParam);
          if (!getImageUrl || getImageUrl?.data.detail === 'Image not found') {
            const getOldImageUrl = await urlTempImage(
              userCookie.userToken,
              oldParam,
            );
            return getOldImageUrl.data.message;
          }
          setIsImageFound(true);
          return show_thumbnail
            ? getImageUrl.data.signed_url
            : getImageUrl.data.message;
        } catch (error) {
          console.error('Error fetching image URL:', error);
          setIsImageFound(false);
          setRetryCount(retryCount + 1);
        } finally {
          setIsImageLoading(false);
        }
      }
      return process.env.REACT_APP_ENV === 'development'
        ? 'https://dummyimage.com/100x56/000000/ffffff.jpg'
        : '';
    },
    isImageLoading,
    isImageFound,
    setIsImageLoading,
  };
};
