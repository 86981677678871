import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useVisibilityObserver } from 'react-visibility-observer';
import { ImageLoader } from './ImageLoader';
import ImageWithAnnotation from './ImageWithAnnotation';
import { ReactComponent as OfflineCamera } from '../../assets/icons/offline-camera.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { useObservationImage } from '../../hooks/image';
import { ObservationProp } from '../../typescript/observation/observation';

interface Props {
  observation: ObservationProp;
  onImageClick?: (selectedObservation: ObservationProp) => void;
}

export function LazyLoadedImage({ observation, onImageClick }: Props) {
  const { user } = useAuthContext();
  const { theme } = useThemeContext();
  const { isVisible } = useVisibilityObserver();
  const {
    isImageLoading,
    isImageFound,
    setIsImageLoading,
    getObservationImageUrl,
  } = useObservationImage();

  const [observationDetail, setObservationDetail] =
    useState<ObservationProp>(observation);

  useEffect(() => {
    if (isVisible) {
      const subscribe = async () => {
        const imageURL = await getObservationImageUrl(observation, 'thumbnail');
        setObservationDetail({
          ...observationDetail,
          imageUrl: imageURL,
        });
      };

      subscribe();
    }
  }, [isVisible]);

  if (!isVisible || isImageLoading) {
    return (
      <ImageLoader className={`loading-image loading-image-${theme} ms-2`} />
    );
  }

  if (!isImageFound) {
    return (
      <div
        className={`observation-image-container-offline ${theme === 'light' ? 'dark' : 'light'}`}
        style={{ width: 90, height: 50 }}
      >
        <OfflineCamera width="50%" height="50%" />
      </div>
    );
  }

  const handleImageClick = (e: React.MouseEvent) => {
    if (onImageClick) {
      e.stopPropagation();
      onImageClick(observationDetail);
    }
  };

  const showAnnotation =
    user?.customer.feature_flags.observations.show_annotation;
  const isAnnotation =
    showAnnotation && observationDetail.id >= Number(showAnnotation);

  if (isAnnotation) {
    return (
      <ImageWithAnnotation
        className={`add-cursor ms-2 ${isImageLoading && 'd-none'} ph-no-capture`}
        containerWidth="90px"
        containerHeight="50px"
        isList={false}
        onImageLoading={isImageLoading}
        isActionEnable={false}
        onClick={handleImageClick}
        onLoad={() => setIsImageLoading(false)}
        imageUrl={observationDetail.imageUrl}
        detections={observationDetail.detections}
      />
    );
  }

  return (
    <Image
      className={`add-cursor ms-2 ${isImageLoading && 'd-none'} ph-no-capture`}
      onClick={handleImageClick}
      onLoad={() => setIsImageLoading(false)}
      width="90"
      height="50"
      src={observationDetail.imageUrl}
    />
  );
}

export default LazyLoadedImage;
