/* eslint-disable react/display-name */
import Konva from 'konva';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Circle, Group, Line } from 'react-konva';
import { DangerZoneResult } from '../../../../typescript/camera/camera';

export interface CanvasRef {
  getStartPoint(): any; // TODO: Define the return type
}

type ZoneSize = {
  width: number;
  height: number;
};

interface Props {
  selectedZoneColor: string;
  dangerZone: DangerZoneResult;
  zoneSize: ZoneSize;
  curMousePos: any;
  selectedZoneIndex?: number;

  onDblTapGroup?: (id: number) => void;
  onDragEnd: (evt: Konva.KonvaEventObject<DragEvent>, index: number) => void;
}

function getMultipliedOfPostions(partialValue: number, totalValue: number) {
  return partialValue * totalValue;
}

export const DangerZone = forwardRef<CanvasRef, Props>(
  (
    {
      selectedZoneColor,
      dangerZone,
      zoneSize,
      curMousePos,
      onDblTapGroup,
      selectedZoneIndex,
      onDragEnd,
    }: Props,
    ref,
  ) => {
    // state
    const [isMouseOverStartPoint, setIsMouseOverStartPoint] = useState(false);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
      if (selectedZoneIndex === dangerZone.id) {
        const animation = setInterval(() => {
          setOpacity((prevOpacity) => {
            // Reset opacity to 1 if it reaches a threshold (e.g., 0.1)
            if (prevOpacity <= 0.1) {
              return 1;
            }
            return prevOpacity - 0.1;
          });
        }, 100);

        // Cleanup the animation interval
        return () => clearInterval(animation);
      }
      return () => false;
    }, [selectedZoneIndex]);

    useImperativeHandle(ref, () => ({
      getStartPoint() {
        return isMouseOverStartPoint;
      },
    }));

    const handleMouseOverStartPoint = (
      event: Konva.KonvaEventObject<MouseEvent>,
    ) => {
      if (dangerZone.completed || dangerZone.zonePoints.length < 3) return;
      event.target.scale({ x: 2, y: 2 });
      setIsMouseOverStartPoint(true);
    };

    const handleMouseOutStartPoint = (
      event: Konva.KonvaEventObject<MouseEvent>,
    ) => {
      event.target.scale({ x: 1, y: 1 });
      setIsMouseOverStartPoint(false);
    };

    const flattenedPoints = dangerZone.zonePoints
      .concat(dangerZone.completed ? [] : curMousePos)
      .reduce((a, b) => a.concat(b), [] as number[]);

    const newArray = flattenedPoints.map((value, index) => {
      if (index % 2) {
        return getMultipliedOfPostions(value, zoneSize.height);
      }
      return getMultipliedOfPostions(value, zoneSize.width);
    });

    return (
      <Group
        onClick={(e) => {
          e.cancelBubble = true;
          onDblTapGroup?.(dangerZone.id);
        }}
      >
        <Line
          points={newArray}
          fill={`${selectedZoneIndex === dangerZone.id ? '#29AD5950' : `${selectedZoneColor}50`}`}
          stroke={`${selectedZoneIndex === dangerZone.id ? '#29AD59' : `${selectedZoneColor}50`}`}
          strokeWidth={1}
          closed={dangerZone.completed}
        />
        {dangerZone.zonePoints.map((point, index) => {
          const width = 10;
          const x =
            getMultipliedOfPostions(point[0], zoneSize.width) - width / 15;
          const y =
            getMultipliedOfPostions(point[1], zoneSize.height) - width / 15;

          return (
            <Circle
              // eslint-disable-next-line react/no-array-index-key
              key={`${x}-${y}-${index}`}
              x={x}
              y={y}
              width={width}
              height={width}
              fill={`${selectedZoneIndex === dangerZone.id ? '#29AD5980' : `${selectedZoneColor}`}`}
              stroke={`${selectedZoneIndex === dangerZone.id ? '#29AD59' : `${selectedZoneColor}`}`}
              strokeWidth={1}
              draggable={
                selectedZoneIndex === dangerZone.id || !dangerZone.completed
              }
              onDragEnd={(event) => onDragEnd(event, dangerZone.id)}
              onMouseOver={handleMouseOverStartPoint}
              onMouseOut={handleMouseOutStartPoint}
              opacity={selectedZoneIndex === dangerZone.id ? opacity : 1}
            />
          );
        })}
      </Group>
    );
  },
);
