/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import moment from 'moment';
import React from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import i18n from '../../utils/i18n';

interface Props {
  active?: boolean;
  payload?:
    | Payload<string | number | (string | number)[], string | number>[]
    | undefined;
}
export function CustomTooltip({ active, payload }: Props) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();

  const scenarioList = payload && payload.map((item) => item.dataKey);

  const filteredScenario =
    scenarioList &&
    scenariosList.filter((element) => scenarioList.includes(element.value));

  if (active && payload && payload.length) {
    return (
      <div
        className={`h-100 p-3 border border-${theme} ${theme} border-radius`}
      >
        <p className="m-0 ph-no-capture">{`Date: ${moment(payload[0].payload.range).format('DD MMM, HH:mm')}`}</p>
        {filteredScenario?.map((item, index) => (
          <span
            key={item.value}
            className="scenario-list d-flex mt-12 ph-no-capture"
            style={{
              backgroundColor: theme === 'light' ? item.colorLight : item.color,
              color: theme === 'light' ? item.color : '#fff',
            }}
          >
            {`${i18n.t(item.title)}: ${payload[index].value}`}
          </span>
        ))}
      </div>
    );
  }
  return null;
}
