import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllAlertRules, removeAlert } from '../apis/api-request';
import { ReactComponent as DarkNotificationBackground } from '../assets/images/smart-notification-dark.svg';
import { ReactComponent as NotificationBackground } from '../assets/images/smart-notification.svg';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import Loader from '../components/elements/Loader';
import ConfirmationModal from '../components/modal/ConfirmationModal';
import OldRuleItem from '../components/smart-alerts/OldRuleItem';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { defaultModalValue, ModalType } from '../typescript/components/modal';
import { RuleProps } from '../typescript/smart-alert/old-smart-alert';
import i18n from '../utils/i18n';

export const OldSmartAlertsPage = () => {
  const { userCookie, onTokenSave } = useAuthContext();
  const { theme } = useThemeContext();

  const [loading, setLoading] = useState(true);

  const [alerts, setAlerts] = useState<Array<RuleProps>>([]);
  const [seletedAlert, setSeletedAlert] = useState<RuleProps | undefined>();

  // Confirmation Modal
  const [confirmationModal, setConfirmationModal] =
    useState<ModalType>(defaultModalValue);

  // Navigate
  const navigate = useNavigate();

  async function deleteAlertRule() {
    const param = {
      id: seletedAlert?.id,
    };

    const deleteAlert = await removeAlert(userCookie.userToken, param);

    setLoading(false);
    if (deleteAlert.status === 401) {
      onTokenSave('');
    } else if (deleteAlert.status === 200) {
      toast.success(
        `${seletedAlert?.title} ${i18n.t('toast.success.is_deleted_successfully')}`,
      );
      setAlerts(alerts.filter((item: RuleProps) => item !== seletedAlert));
    }
  }

  const handleShowConfirmation = (isTrue: boolean) => {
    if (isTrue) {
      deleteAlertRule();
    }

    setConfirmationModal(defaultModalValue);
  };

  useEffect(() => {
    const subscribe = async () => {
      if (userCookie) {
        const getAllRules = await getAllAlertRules(userCookie.userToken);

        setLoading(false);
        if (getAllRules.status === 401) {
          onTokenSave('');
        }

        setAlerts(getAllRules.data);
      }
    };
    subscribe();
  }, []);

  return (
    <>
      <Breadcrumb title={`${i18n.t('dashboard.title')}`} toUrl="/" />
      <h5>{i18n.t('smart_notifications.title')}</h5>
      <Row className="smart-alerts">
        {loading && (
          <Col className="text-center empty-list" md={12}>
            <Loader main />
          </Col>
        )}
        {!loading && alerts.length === 0 && (
          <Col md={6} className="text-center mx-auto align-self-center">
            {theme === 'light' ? (
              <NotificationBackground />
            ) : (
              <DarkNotificationBackground className="dark-image" />
            )}
            <h6 className="mt-24">
              {i18n.t('smart_notification.empty.list.title')}
            </h6>
            <p className="m-0">
              {i18n.t('smart_notification.empty.list.body')}
            </p>

            <Link
              to="/create-rule"
              className="btn btn-primary rounded-pill mt-24"
            >
              {i18n.t('button.new_notifications_rule')}
            </Link>
          </Col>
        )}

        {!loading && alerts.length !== 0 && (
          <Col md={12}>
            <div className="d-flex flex-column align-items-end">
              <Link
                key="0"
                to="/create-rule"
                className="btn btn-primary rounded-pill button-with-loader"
              >
                {i18n.t('button.new_notifications_rule')}
              </Link>

              <Table
                responsive={`sm w-100 alerts-table overflow-hidden border border-${theme} border-radius`}
                hover
                variant={theme}
                key="1"
              >
                <thead className="weight-500">
                  <tr>
                    <td>{i18n.t('td.alert')}</td>
                    <td>{i18n.t('td.scenarios')}</td>
                    <td>{i18n.t('td.camera_name')}</td>
                    <td>{i18n.t('td.actions')}</td>
                  </tr>
                </thead>
                <tbody>
                  {alerts.map((alert: RuleProps) => (
                    <OldRuleItem
                      key={alert.id}
                      alert={alert}
                      onEdit={() => navigate(`/edit-rule/${alert.id}`)}
                      onDelete={() => {
                        setSeletedAlert(alert);
                        setConfirmationModal({
                          title: 'Delete Rule',
                          body: `Are you sure you want to delete "${alert.title}"`,
                          buttonType: 'danger',
                          doneText: 'Yes, delete',
                          cancelText: 'No, keep it',
                          show: true,
                        });
                      }}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        )}
        <ConfirmationModal
          modalValue={confirmationModal}
          onCloseClick={(e) => handleShowConfirmation(e)}
        />
      </Row>
    </>
  );
};
