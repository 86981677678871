/* eslint-disable max-len */
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel.svg';
import { useAuthContext } from '../../../../contextapi/AuthProvider';
import { useThemeContext } from '../../../../contextapi/ThemeProvider';
import i18n from '../../../../utils/i18n';
import { CustomButton } from '../../../elements/CustomButton';

interface Props {
  show: boolean;
  onButtonClick: (() => void) | undefined;
}

export function NoScenarioModal({ show, onButtonClick }: Props) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();

  const scenarioArray = scenariosList.filter(
    (isScenario) => isScenario.isDangerZone && isScenario.isDangerZone,
  );
  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal"
      show={show}
      onHide={onButtonClick}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera.edit_scenarios.title')}</Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          type="button"
          onClick={onButtonClick}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <p className="weight-600">{i18n.t('modal.no_scenario.title')}</p>

            <div className="scenario-tag">
              <ul>
                {scenarioArray.map((scenario) => (
                  <li
                    className="scenario-list me-2 mb-12"
                    key={scenario.value}
                    style={{
                      backgroundColor:
                        theme === 'light'
                          ? scenario.colorLight
                          : scenario.color,
                      color: theme === 'light' ? scenario.color : '#fff',
                    }}
                  >
                    {i18n.t(scenario.title)}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
