/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import i18n from '../../utils/i18n';

interface Props {
  payload?:
    | Payload<string | number | (string | number)[], string | number>[]
    | undefined;
}
export function CustomLegend({ payload }: Props) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();

  const scenarioList = payload && payload.map((item) => item.dataKey);

  const filteredScenario =
    scenarioList &&
    scenariosList.filter((element) => scenarioList.includes(element.value));

  if (payload) {
    return (
      <div className="d-flex justify-content-center mt-12">
        {filteredScenario?.map((item) => (
          <span
            key={item.value}
            className="scenario-list d-flex mx-2"
            style={{
              backgroundColor: theme === 'light' ? item.colorLight : item.color,
              color: theme === 'light' ? item.color : '#fff',
            }}
          >
            {`${i18n.t(item.title)}`}
          </span>
        ))}
      </div>
    );
  }
  return null;
}
